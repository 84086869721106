import api from "../../../api/axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useSignupMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    async ({ emailId, password, firstName, lastName, phoneNumber, otp }) => {
      const payload = {
        firstName,
        lastName,
        emailId,
        password,
        phoneNumber,
        otp,
      };
      const signupResponse = await api.post("/users/signup", payload);
      console.log("Response ye hai", signupResponse);
      // const { token } = signupResponse.data;
      // localStorage.setItem("token",token)
      // if (!token) {
      //   throw new Error("Login failed");
      // }
      return signupResponse.data;
    },
    {
      onSuccess: (signupResponse) => {
        navigate("/dashboard/orders");
        toast.success("User Created", { toastId: "toast-message" });
        console.log("user created");
      },
      onError: (error) => {
        toast.error(error.response.data.message, {
          toastId: "toast-message",
        });
      },
    }
  );
};

export default useSignupMutation;
