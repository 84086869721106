import React, { useState, useEffect } from "react";
import { GrLocation } from "react-icons/gr";
import useAddAddress from "../../../hooks/mutations/useAddAddress";
import { Country, State, City } from 'country-state-city';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
var getAddress = require('extract-country-state-city-from-zip');

const AddAddress = ({
  addressArray,
  setAddressArray,
  isEnabled,
  setSelectedAddress,
  setAddressFlag,
}) => {
  const navigate = useNavigate();
  const [label, setLabel] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();
  const [zipError, setZipError] = useState();
  const addAddress = useAddAddress();

  const countries = Country.getAllCountries();
  useEffect(() => {
    setStateList(State.getStatesOfCountry(country));
  }, [country]);

  useEffect(() => {
    setCityList(City.getCitiesOfState(country, state));
  }, [stateList, state]);

  const handleSubmit = async () => {
    const validation = validatePostcode();
    if (validation) {
      const payload = {
        label,
        city,
        country,
        state,
        zipCode,
        line1,
        line2,
      };
      await setAddressArray((addressArray) => [...addressArray, payload]);
      addAddress.mutate(payload);
    } else {
      setZipError(true);
      toast.error("invalid zip", { toastId: "toast-message" });
    }
  };

  const validatePostcode = () => {
    const isvalid = postcodeValidator(zipCode, country);
    return isvalid;
  };

  return (
    isEnabled && (
      <div>
        <label
          htmlFor="my-modal-1"
          className="add-address-card flex flex-col gap-2 cursor-pointer items-center justify-center"
        >
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#FD004E] text-3xl font-bold">
              <GrLocation />
            </p>
            <p className="text">Add Address</p>
          </div>
        </label>

        <input type="checkbox" id="my-modal-1" class="modal-toggle" />
        <div class="modal">
          <div class="modal-box relative bg-secondary">
            <label
              htmlFor="my-modal-1"
              class="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 class="text-lg font-bold mb-6">Add New Address</h3>

            <span class="text-xl my-1">Line 1</span>
            <input
              type="text"
              placeholder="221B Baker Street"
              class="input input-bordered bg-[#1B1C2D] w-full my-3"
              name="value"
              onChange={(e) => {
                setLine1(e.target.value);
              }}
            />
            <span class="text-xl my-1">Line 2</span>
            <input
              type="text"
              placeholder="221B Baker Street"
              class="input input-bordered bg-[#1B1C2D] w-full my-3"
              name="value"
              onChange={(e) => {
                setLine2(e.target.value);
              }}
            />
            <div class="text-xl my-1">Country</div>
            <select
              onChange={(e) => setCountry(e.target.value)}
              className="select w-full  bg-[#1B1C2D] w-full my-3"
            >
              <option disabled selected>
                Select Your Country
              </option>
              {countries.map((item, index) => {
                return <option value={item.isoCode}>{item.name}</option>;
              })}
            </select>
            <div class="text-xl my-1">State</div>
            <select
              onChange={(e) => setState(e.target.value)}
              className="select w-full  bg-[#1B1C2D] w-full my-3"
            >
              <option disabled selected>
                Select Your State
              </option>
              {stateList.map((item, index) => {
                return <option value={item.isoCode}>{item.name}</option>;
              })}
            </select>
            <span class="text-xl my-1">City</span>
            <select
              onChange={(e) => setCity(e.target.value)}
              className="select w-full  bg-[#1B1C2D] w-full my-3"
            >
              <option disabled selected>
                Select Your City
              </option>
              {cityList.map((item, index) => {
                return <option value={item.isoCode}>{item.name}</option>;
              })}
            </select>
            <div class="text-xl my-1">ZIP Code</div>
            <input
              type="text"
              placeholder="e.g. 560066"
              class="input input-bordered bg-[#1B1C2D] w-full my-3"
              name="title"
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
            {zipError ? (
              <div className="text-[#FF0000] text-xs">invalid zip !!!</div>
            ) : (
              ""
            )}

            <div class="text-xl my-1">Label</div>
            <input
              type="text"
              placeholder="e.g. Home, Office, Rahul's, etc."
              class="input input-bordered bg-[#1B1C2D] w-full my-3"
              name="title"
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
            <label
              htmlFor="my-modal-1"
              onClick={handleSubmit}
              className="btn secondary-button w-full mt-3"
            >
              Save
            </label>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
  );
};

export default AddAddress;
