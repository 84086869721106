import api from "../../../api/axios";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const useVerifyOTPMutation = () => {

    const navigate = useNavigate();
    return useMutation(
        async ({ emailId, otp, password }) => {
            const VerifyOTPResponse = await api.post(`/users/update-password`, {
                emailId: emailId,
                otp: otp,
                password: password
            });
            const { _id } = VerifyOTPResponse.data.data;
            if (!_id) {
                toast.error("Update failed", { toastId: "toast-message" })
            }
            return VerifyOTPResponse;

        },
        {
            onSuccess: (VerifyOTPResponse) => {
                navigate("/login");
                toast.success("Password updated successfully.", { toastId: "toast-message" });

            },
            onError: (error) => {
                toast.error(error.response.data.message, { toastId: "toast-message" })
            },
        }
    );
};

export default useVerifyOTPMutation;
