import React, { useState, useEffect } from "react";
import loginimage from "../../assets/images/homepage/BG Image.png";
import { AiOutlineLeft } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import useLoginMutation from "../../hooks/mutations/auth/loginUserMutation";
import back from "../../assets/images/login-signup/Mask group(1).png";
import logo from "../../assets/images/logo/grey_compress.png";
import isEmail from "validator/lib/isEmail";
import validator from "validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrGoogle } from "react-icons/gr";
import { BiHide } from "react-icons/bi";
import { BiShowAlt } from "react-icons/bi";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import api from "../../api/axios";
import ReactPixel from 'react-facebook-pixel';

const Login = ({ admin, setAdmin, setUserId }) => {
  const loginMutation = useLoginMutation();
  const navigate = useNavigate();
  const [visible, setVisibilty] = useState(false);

  const [emailId, setEmailId] = useState();
  const [password, setPassword] = useState();
  const [isRemember, setIsRemember] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard/orders");
    }
  }, [navigate]);

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    setIsRemember(rememberMe === "true");
  }, []);

  useEffect(() => {
    localStorage.setItem("rememberMe", isRemember);
  }, [isRemember]);

  const handleRememberMeChange = () => {
    setIsRemember(!isRemember);
  };


  const togglePassword = () => {
    setVisibilty(!visible);
    console.log("test")
  };
  useEffect(() => {
    console.log(visible);
  }, [visible]);
  useEffect(() => {
    ReactPixel.track("ViewContent", {
      content_type: "login",
    });
  }, []);

  const handleSubmit = () => {
    if (!emailId) {
      toast.error("Please Enter Your Email", { toastId: "toast-message" });
    } else if (!isEmail(emailId)) {
      toast.error("Please Enter a Valid Email", { toastId: "toast-message" });
    } else if (!password) {
      toast.error("Please Enter a Valid Password", {
        toastId: "toast-message",
      });
    } else {
      const response = loginMutation.mutate({
        emailId: emailId,
        password: password,
        setAdmin,
        admin,
        setUserId,
        isRemember,
      });
      ReactPixel.track("Login", {
        method: "email",
      });
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await api.post("/users/google-login", {
          code: codeResponse,
        });
        localStorage.setItem("token", response.data.token);
        navigate("/dashboard/create");
        toast.success("Login Successful", { toastId: "toast-message" });
        ReactPixel.track("Login", {
          method: "google",
        });
      } catch (error) {
        console.error(error);
        toast.error("Login failed", { toastId: "toast-message" });
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Login failed", { toastId: "toast-message" });
    },
    flow: "auth-code",
  });

  return (
    <div className="w-full h-full">
      <div className="grid grid-cols-1 lg:grid-cols-2 h-screen lg:h-full">
        <div className=" hidden lg:flex">
          <div className="w-full flex justify-center object-contain">
            <div className="absolute top-10 left-10">
              <a
                className="btn btn-ghost hover:bg-transparent text-white normal-case text-xl"
                href="/"
              >
                <figure className="image nav-image" alt="logo">
                  <img
                    onClick={() => navigate("/")}
                    src={logo}
                    className="nav-logo-image w-[65px] h-[65px]"
                    alt="nav-logo"
                  />
                </figure>
              </a>
            </div>

            <img
              src={loginimage}
              className="w-full object-contain"
              alt="LoginImage"
            />
          </div>
        </div>

        <div className="flex w-full justify-center align-center p-4 md:p-0">
          <div className="form-container w-full z-10 text-white grid place-items-center justify-items-center">
            <div
              // onSubmit={handleSubmit(onSubmit)}
              className="flex-col place-items-center py-6 lg:w-1/2"
            >
              <div className="flex justify-between">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className=" text-xl font-bold"
                >
                  <AiOutlineLeft />
                </button>
                <button
                  onClick={() => {
                    navigate("/signup");
                  }}
                  className="btn btn-ghost text-xs font-bold"
                >
                  Signup
                </button>
              </div>
              <div className="font-semibold text-3xl mb-12 mt-5 text-center">
                Login to your account
              </div>
              {/* {loginMutation?.error instanceof Error && <p>{loginMutation?.error?.response?.data?.message!}</p>} */}

              <div className="flex flex-col text-left relative">
                <span class="text-xl my-3">Email Id</span>
                <input
                  label="Email"
                  className="input input-bordered bg-[#1B1C2D] w-full"
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
                <span class="text-xl my-3">Password</span>
                {
                  <icon onClick={togglePassword}>
                    {visible ? (
                      <BiHide className=" w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-4" />
                    ) : (
                      <BiShowAlt className="w-8 h-8 absolute top-1/2 transform -translate-y-1/2 right-4" />
                    )}
                  </icon>
                }
                <input
                  label="Password"
                  type={visible ? "text" : "password"}
                  className="input input-bordered bg-[#1B1C2D] w-full"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />

                <div className="flex justify-between my-8 flex-wrap text-sm sm:text-base">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-5 w-5 rounded-full shadow"
                      checked={isRemember}
                      onChange={handleRememberMeChange}
                    />
                    <label className="mx-1">Remember Me</label>
                  </div>
                  <div
                    className="underline hover:text-slate-700 text-sm"
                  // onClick={() => {
                  //     setShowDialog(true);
                  // }}
                  >
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loginMutation.isLoading}
                  className="secondary-button disabled:bg-opacity-70 w-full mt-6"
                >
                  {loginMutation.isLoading ? "Signing In" : "Sign In"}
                </button>
              </div>
              <div className="" id="signInDiv">
                <button n onClick={login} className="block w-full border-2  text-black  rounded-lg p-2 my-3 bg-white disabled:bg-opacity-70 hover:bg-gray-200">
                  <span className="text-sm sm:text-base">
                    <FcGoogle className="inline mx-4 scale-150" />
                    Continue With Google
                  </span>
                </button>
                {/* <GoogleLogin
                  onSuccess={credentialResponse => {
                    console.log(credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login
