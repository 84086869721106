import { useEffect, useRef, useState } from "react";
import Switch from "./components/Switch";
import { FiDollarSign } from "react-icons/fi";
import { FiServer } from "react-icons/fi";
import status from "../../assets/images/logo/status.svg";
import image1 from "../../assets/images/cards/Rectangle 29852.png";
import image2 from "../../assets/images/cards/Rectangle 29852(2).png";
import useGetOrder from "../../hooks/queries/useGetOrder";
import useGetAllOrders from "../../hooks/queries/orders/useGetAllOrders";
import useGetMyOrders from "../../hooks/queries/orders/useGetMyOrders";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { AiOutlineSend } from "react-icons/ai";
import api from "../../api/axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo/grey_compress.png";
import { BiUser } from "react-icons/bi";
import { toast } from "react-toastify";
import Active from "./components/Active";
import Searched from "./components/Searched";
import { useSpinner } from "../../context/Spinner";

const STATUS_CODES = {
  pending: 0,
  reviewed: 1,
  paid: 2,
  processing: 3,
  completed: 4,
  cancelled: 5,
};

const Users = ({ admin, userId }) => {
  const paypal = useRef();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [order, setOrder] = useState(null);
  // const [admin, setAdmin] = useState(false)
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState();
  const [amount, setAmount] = useState();
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchtext, setSearchtext] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchUsers, setSearchUsers] = useState([]);
  const spinner = useSpinner();
  // const orders = useGetAllOrders();
  // const myOrders = useGetMyOrders(userId);

  const currency = "USD";
  const updateStatus = async (status) => {
    const response = await api.put("/orders/", {
      orderId: order._id,
      status,
    });
  };
  const downloadAllUsers = async () => {
    try {
      const response = await api.get(`/users/export`);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "users.csv";
      a.click();
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUsers = async () => {
    const { data } = await api.get("/users/all");
    // console.log(data, "ye hai users");
    setUsers(data);
  };

  const fetchOrders = async () => {
    const { data } = await api.get("/orders");
    // console.log(data);
    setOrders(data);
  };

  const fetchOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.get("/orders/details/" + id);
      setOrder(response.data);
    }
    setLoading(false);
  };

  const deleteOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.delete("/orders/delete/" + id);
      setOrder(response.data);
    }
    toast.success("Order deleted successfully");
    fetchOrders();
    setLoading(false);
  };
  const handleReview = async () => {
    const response = await api.post("/orders/review", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    setPrice();
    console.log(response.data);
  };

  const handleReview_update_price = async () => {
    const response = await api.post("/orders/review", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    setPrice();
    console.log(response.data);
  };

  const handleComment = async (e) => {
    e.preventDefault();
    const response = await api.post("/orders/comment", {
      orderId: order._id,
      comment: comment,
    });
    fetchOrder(order._id);
    setComment("");
    console.log(response.data);
  };

  useEffect(() => {
    fetchOrder(selectedOrder);
  }, [selectedOrder]);
  // useEffect(() => {
  //   fetchUsers();
  //   fetchOrders();
  // }, []);
  useEffect(() => {
    fetchOrder(selectedOrder);
  }, [selectedOrder]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchOrder(selectedOrder);
    }, 1000 * 5); // in milliseconds
    return () => clearInterval(intervalId);
  });

  const HandleSearchUsers = async (searchtext) => {
    // e.preventDefault();
    // console.log(searchtext, "ye query search test hai");
    spinner.setLoadingState(true);
    const response = await api.get(`/users/search?keyword=${searchtext}`);
    setSearchUsers(response.data);
    // console.log("search ka results", response.data);
    spinner.setLoadingState(false);
  };

  const removeQueryParams = () => {
    const param = searchParams.get("q");
    if (param) {
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    removeQueryParams();
  }, [searchParams.length == 0]);

  useEffect(() => {
    const param = searchParams.get("q");
    // console.log("Param to searc in api", param);
    HandleSearchUsers(param);
  }, [searchParams]);

  useEffect(() => {
    HandleSearchUsers("");
  }, []);

  return (
    <div className="drawer drawer-end w-full min-h-screen">
      <input id="my-drawer-9" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-8 lg:pr-0 w-full h-full">
        <section className="text-white text-left relative">
          <div className="flex flex-col lg:flex-row justify-between items-center w-full">
            <p className="text-5xl font-bold mb-6">All Users</p>
            <form className="form-control" onSubmit={HandleSearchUsers}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  placeholder="Search for users"
                  className="input input-bordered bg-[#1B1C2D] w-60"
                  onChange={(e) => setSearchParams({ q: e.target.value })}
                />
                <button className="btn btn-square" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <Searched
            setSelectedOrder={setSelectedOrder}
            isAdmin={admin}
            deleteOrder={deleteOrder}
            orders={searchUsers}
            setAmount={setAmount}
            downloadAllUsers={downloadAllUsers}
          />
        </section>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-9" className="drawer-overlay"></label>
        <ul className="menu example fixed top-0 right-0 flex-1  scrollbar-hide p-4 mt-16 pb-16 w-11/12 text-base-content bg-[#06011A] lg:w-1/3"></ul>
      </div>
    </div>
  );
};

export default Users;
