import React, { useEffect, useState } from "react";
import AddAddress from "./components/AddAddress";
import FileUpload from "./components/FileUpload";
import useCreateOrderMutation from "../../hooks/mutations/orders/createOrderMutation";
import useGetAllAddressses from "../../hooks/queries/useGetAllAddresses";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSpinner } from "../../context/Spinner";
import { MdDeleteOutline } from "react-icons/md";
const Create = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orderMutation = useCreateOrderMutation();
  const spinner = useSpinner();
  const [images, setImages] = useState();
  const [imagefiles, setImageFiles] = useState();
  const [checked, setChecked] = useState(false);
  const [addressArray, setAddressArray] = useState([]);
  const [title, setTitle] = useState();
  const [note, setNote] = useState();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [length, setLength] = useState();
  const [addressFlag, setAddressFlag] = useState();
  const [size, setSize] = useState("N/A");
  const addresses = useGetAllAddressses();
  const MAX_SIZE_MB = 16; // Maximum file size in megabytes
  const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert to bytes

  const renderImages = () => {
    if (images) {
      return images.map((photo) => {
        return (
          <div
            onClick={() => {
              const imageIndex = images.indexOf(photo);
              const _images = images.filter(
                (image, index) => index !== imageIndex
              );
              setImages(_images);
            }}
            className="add-image-card relative"
          >
            <button className="absolute top-o right-0 fixed bg-gradient-to-br from-transparent to-[#2d1582] text-white rounded-full p-2 hover:from-[#fd004e] hover:to-[#2d1582]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={photo}
              alt="plc"
            />
          </div>
        );
      });
    }
  };

  const createProject = () => {
    let totalSize = 0;

    const filesArray = Array.from(imagefiles);

    if (filesArray.length > 0) {
      totalSize = filesArray.reduce((acc, file) => acc + file.size, 0);

      console.log(
        `Total size of files being uploaded: ${totalSize} bytes (${(
          totalSize /
          1024 /
          1024
        ).toFixed(2)} MB)`
      );
    }

    if (!images || images.length === 0) {
      toast.error("Please select at least one image", {
        toastId: "toast-message",
      });
      return; // Stop execution if validation fails
    }
    if (!title) {
      toast.error("Please enter a title", { toastId: "toast-message" });
      return;
    }
    if (!note) {
      toast.error("Please enter a description", { toastId: "toast-message" });
      return;
    }
    if (totalSize > MAX_SIZE_BYTES) {
      toast.error(`Total image size exceeds the ${MAX_SIZE_MB} MB limit`, {
        toastId: "toast-message-size-limit",
      });
      setIsLoading(false);
      return; // Stop execution
    }

    setIsLoading(true); // Start loading
    setIsCreating(true); // Indicate creation process is starting

    orderMutation.mutate(
      {
        title: title,
        notes: note,
        images: imagefiles,
        toPrint: checked,
        size: size,
        addressToShip: selectedAddress,
        setLoading: spinner.setLoadingState,
      },
      {
        onSuccess: () => {
          setIsLoading(false); // Stop loading on success
          setIsCreating(false); // Reset creation indicator
        },
        onError: () => {
          setIsLoading(false); // Ensure loading is stopped on error
          setIsCreating(false); // Reset creation indicator
        },
      }
    );
  };

  // disable the button when a mutation is in progress
  const disabled = isCreating;

  useEffect(() => {
    if (addresses.data) {
      setAddressArray(addresses.data);
    }
  }, [addresses, addressFlag]);

  return (
    <section className="p-8  text-white text-left flex justify-center h-auto overflow-y-auto pb-40">
      <div className=" h-full">
        <p className="text-5xl font-bold mb-6">Create New Project</p>
        <div className="flex flex-col h-full">
          <span class="text-xl my-2">Add Reference Images*</span>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
            {renderImages()}
            <div className="add-image-card  gap-2 cursor-pointer  ">
              <FileUpload
                setImages={setImages}
                images={images}
                setImageFiles={setImageFiles}
              />
            </div>
          </div>

          <p className="text text-[#7E7E7E] mt-5">
            File types supported: JPG, PNG, GIF Max file-size: 16 MB
          </p>
          <div class="form-control w-full text-white my-2">
            <span class="text-xl my-3">Title*</span>
            <input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              type="text"
              placeholder="Add a title for your model e.g. Halo Prism"
              class="input input-bordered bg-[#1B1C2D] w-full"
              required
            />
            <span class="text-xl my-3">Leave a note*</span>
            <textarea
              maxLength={300}
              onChange={(e) => {
                setNote(e.target.value);
                setLength(e.target.value.length);
              }}
              type="text"
              placeholder="Add any special instructions here"
              class="textarea textarea-bordered bg-[#1B1C2D] w-full"
              required
            />
            {length ? (
              <div className="pt-2">{300 - length} words left</div>
            ) : (
              ""
            )}

            <label class="label flex justify-start gap-3 cursor-pointer my-4">
              <input
                type="checkbox"
                class="toggle toggle-accent"
                checked={checked}
                onClick={() => setChecked(!checked)}
              />
              <span className="text mt-1">
                Print this model in 3D and send it to me <br />
                {/* <span className="text-[#7E7E7E]"> (we use raisin printer with xxx dpi)<br/>(Additional charges apply) </span> */}
              </span>
            </label>
            {checked ? (
              <div>
                <div className="text-xl pb-4">Enter a size</div>
                <input
                  onChange={(e) => setSize(e.target.value)}
                  min="0.1"
                  max="16"
                  onkeypress="return event.charCode >= 48"
                  ma
                  type="number"
                  placeholder="Enter a size(0.1 to 16 inches)"
                  className="input input-bordered w-full max-w-xs bg-[#1B1C2D] mb-4"
                />
              </div>
            ) : (
              ""
            )}
            <div className="grid grid-cols-1 w-full md:grid-cols-2 gap-3 my-4">
              {checked &&
                addressArray.map((item) => {
                  return (
                    <div
                      onClick={() => setSelectedAddress(item._id)}
                      className={
                        item._id == selectedAddress
                          ? "border-b-2 border-[#FD004E]"
                          : ""
                      }
                    >
                      <div className="add-address-card flex flex-col gap-2 cursor-pointer p-2">
                        <div className="text-[#FD004E]  font-bold">
                          {item.label}
                        </div>
                        <div className="text">
                          {item.line1},{item.line2}
                        </div>
                        <div className="text">
                          {item.city} , {item.state}, {item.country}
                        </div>
                        <div className="text">{item.zipCode}</div>
                      </div>
                    </div>
                  );
                })}
              <AddAddress
                addressArray={addressArray}
                setAddressFlag={setAddressFlag}
                setAddressArray={setAddressArray}
                isEnabled={checked}
                setSelectedAddress={setSelectedAddress}
              />
            </div>
            <button
              onClick={createProject}
              disabled={disabled}
              className="secondary-button w-full my-4 md:w-64"
            >
              {isLoading ? "Creating..." : "Create Project"}
            </button>
            {orderMutation.isLoading && <spinner />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Create;
