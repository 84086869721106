import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  CgLayoutPin,
  CgGift,
  CgExtensionAdd,
  CgFilters,
  CgUser,
} from "react-icons/cg";
import { FaRegHandPeace, FaPatreon } from "react-icons/fa";
import dspot from "../../../../assets/images/logo/grey_compress.png";
import { BiArrowFromRight, BiArrowFromLeft } from "react-icons/bi";
import Lottie from "react-lottie";
import animation from "../../../../assets/animation/arrow.json";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";
import { TbBrandPatreon } from "react-icons/tb";
import { MdSubscriptions } from "react-icons/md";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Sidebar = ({ menuCollapse, setMenuCollapse, admin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("orders");

  const determineActiveItem = (path) => {
    if (path.includes("/dashboard/create")) return "create";
    if (path.includes("/dashboard/orders")) return "orders";
    if (path.includes("/dashboard/settings")) return "settings";
    if (path.includes("/dashboard/join")) return "join";
    if (path.includes("/dashboard/users")) return "users";
    if (path.includes("/dashboard/Couponcode")) return "Couponcode";
    return "orders"; // default active item
  };

  useEffect(() => {
    setActive(determineActiveItem(location.pathname));
  }, [location]);

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };

  const menu_items = [
    {
      label: "Create New",
      link: "create",
      icon: <AiOutlinePlus />,
      path: "/dashboard/create",
    },
    {
      label: "Orders",
      link: "orders",
      icon: <MdSubscriptions />,
      path: "/dashboard/orders",
    },
    {
      label: "Settings",
      link: "settings",
      icon: <IoSettingsOutline />,
      path: "/dashboard/settings",
    },
    {
      label: "Subscription",
      link: "join",
      icon: <TbBrandPatreon />,
      path: "/dashboard/join",
    },
  ];

  const adminmenu = [
    {
      label: "Create Coupon",
      link: "create",
      icon: <AiOutlinePlus />,
      path: "/dashboard/Couponcode",
    },
    {
      label: "Orders",
      link: "orders",
      icon: <CgGift />,
      path: "/dashboard/orders",
    },
    {
      label: "Users",
      link: "users",
      icon: <CgUser />,
      path: "/dashboard/users",
    },
    {
      label: "Settings",
      link: "settings",
      icon: <CgFilters />,
      path: "/dashboard/settings",
    },
    {
      label: "Subscription",
      link: "join",
      icon: <MdSubscriptions />,
      path: "/dashboard/join",
    },
   
  ];

  return (
    <div className="sidebar hidden lg:flex" id="collapsible-sidebar">
      <ProSidebar collapsed={menuCollapse}>
        <div
          className={
            menuCollapse ? "p-4 cursor-pointer" : "py-4 cursor-pointer"
          }
        >
          {menuCollapse ? (
            <div className="flex">
              <img
                onClick={() => navigate("/")}
                src={dspot}
                className="nav-logo-image w-[65px] h-[65px] ml-4"
                alt="nav-logo"
              />
              <BiArrowFromRight
                onClick={menuIconClick}
                className="text-xl text-white"
              />
            </div>
          ) : (
            <div className="flex">
              <img
                onClick={() => navigate("/")}
                src={dspot}
                className="nav-logo-image w-[65px] h-[65px] ml-4"
                alt="nav-logo"
              />
              <div
                className="text-2xl font-bold text-white relative top-[20px] left-[100px]"
                onClick={menuIconClick}
              >
                <BiArrowFromLeft className="text-xl text-white" />
              </div>
            </div>
          )}
        </div>

        <SidebarContent>
          <Menu iconShape="square">
            {admin ? (
              <div>
                {adminmenu.map((item) => (
                  <MenuItem
                    key={item.label} // Unique key for each item
                    className={
                      active === item.link ? "active ml-3" : "non-active ml-3"
                    }
                    onClick={() => setActive(item.link)}
                    icon={item.icon}
                  >
                    <Link to={item.path}>{item.label}</Link>
                  </MenuItem>
                ))}
              </div>
            ) : (
              <div>
                {menu_items.map((item) => (
                  <MenuItem
                    key={item.label} // Unique key for each item
                    className={
                      active === item.link ? "active ml-3" : "non-active ml-3"
                    }
                    onClick={() => setActive(item.link)}
                    icon={item.icon}
                  >
                    <Link to={item.path}>{item.label}</Link>
                  </MenuItem>
                ))}
              </div>
            )}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
