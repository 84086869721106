import React from "react";
import "../../../../sass/files/gallery.scss";
import Carousel from "./component/ReviewCarousel";
import tools from "../../../../assets/images/homepage/Frame 25.png";
import threeds from "../../../../assets/images/galleryicons/Frame 8.svg";
import zbrush from "../../../../assets/images/galleryicons/Frame 9.svg";
import maya from "../../../../assets/images/galleryicons/Frame 10.svg";
import unity from "../../../../assets/images/galleryicons/Frame 11.svg";

import { useRef } from "react";

const Review = () => {
  const ref = useRef();
  return (
    <div>
      <section className="hero gallery-section ">
        <div className="hero-body">
          <p className="gallery-title">Our Testimonials </p>
     
        
          <div className="grid grid-cols-1 gap-4 items-center" id="review">
       
            <div className="flex items-center flex-cols ">
              {/* <img src={tools} alt="" /> */}
              <Carousel />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Review;
