import api from "../../../api/axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSpinner } from "../../../context/Spinner";
import { useState } from "react";

const useCreateOrderMutation = () => {
  const navigate = useNavigate();
  const spinner = useSpinner();
  const [isCreating, setIsCreating] = useState(false);

  return useMutation(
    async ({
      title,
      toPrint,
      size,
      notes,
      images,
      addressToShip,
      setLoading,
      userEmail,
    }) => {
      setIsCreating(true);
      spinner.setLoadingState(true);
      const payload = {
        title,
        toPrint,
        size,
        notes,
        images,
        addressToShip,
        userEmail,
      };
      const formData = new FormData();
      formData.append("title", title);
      formData.append("toPrint", toPrint);
      formData.append("size", size);
      formData.append("notes", notes);
      formData.append("addressToShip", addressToShip);
      formData.append("userEmail", userEmail); // Append userEmail to the formData

      if (images) {
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i]);
        }
      }
      const signupResponse = await api.post("/orders/create", formData);
      console.log("Response ye hai", signupResponse);

      toast.success(
        "Your order has been created successfully, you will get the quotation within 5 minutes.",
        {
          toastId: "toast-message",
        }
      );
      return signupResponse.data;
    },
    {
      onSuccess: (signupResponse) => {
        setIsCreating(false);
        spinner.setLoadingState(false);
        navigate("/dashboard/orders");
        console.log("Order created successfully");
      },
      onError: (error) => {
        setIsCreating(false);
        spinner.setLoadingState(false);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const status = error.response.status;

          if (status === 413) {
            toast.error("Image size exceeds the 16 MB limit", {
              toastId: "toast-message",
            });
          } else {
            toast.error(`Server error: ${status}`, {
              toastId: "toast-message",
            });
          }
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("No response from the server", {
            toastId: "toast-message",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error("An unexpected error occurred", {
            toastId: "toast-message",
          });
        }
      },
      onSettled: () => {
        setIsCreating(false); // Reset isCreating regardless of success or error
      },
    }
  );
};

export default useCreateOrderMutation;
