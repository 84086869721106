import React from 'react'

const FileUpload = ({ setImages, images, setImageFiles }) => {
    const imageFileExtentions = [".jpg", ".jpeg", ".png", ".gif"]


    let fileArray
    const handleFileUpload = (e) => {
        if (e.target.files) {
            setImageFiles(e.target.files)
            fileArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file))
            console.log(fileArray,e.target.files, "ye filearray hai")
            if (images) {
                setImages([...images, ...fileArray])
            } else
                setImages([...fileArray])
            // Array.from(e.target.files).map((file)=>URL.revokeObjectURL(file))
        }
    }

    return (
        <label className="flex w-full h-full justify-center items-center cursor-pointer" htmlFor="dropzone-file">
            <div className="flex flex-col justify-center items-center ">
                <p className="text-[#FD004E] text-3xl font-bold">+</p>
                <p className="text">Add Images</p>
            </div>
            <input className="hidden" id="dropzone-file" type="file" multiple
                onChange={handleFileUpload}
                accept={imageFileExtentions} />
        </label>
    )
}

export default FileUpload
