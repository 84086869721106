import React, { useState, useEffect } from "react";
import Sidebar from "./components/sidebar/index";
import { Outlet } from "react-router-dom";
import Header from "./components/header/index";
import "../../sass/files/sidebar.scss";
import { useNavigate } from "react-router-dom";


const Dashboard = ({ admin }) => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(false);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);
  

  const handleMyOrders = () => {
    setOpen(false);
    <div
      onClick={() => setOpen(false)}
      className="w-screen lg:hidden md:hidden"
    >
      <Outlet />
    </div>;
    navigate("/dashboard/orders");
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };
  const handleHome = () => {

    navigate("/");
  };

  return (
    <div className="flex h-auto bg-[#06011a] w-screen max-w-screen">
      {loading ? (
        "loading"
      ) : (
        <>
          <Sidebar
            admin={admin}
            menuCollapse={menuCollapse}
            setMenuCollapse={setMenuCollapse}
          />
          <div className="flex flex-col w-screen lg:w-full">
            <Header setOpen={setOpen} admin={admin} />
            <div
              onClick={() => setOpen(false)}
              className={menuCollapse ? "outlet-large" : "outlet-small"}
              id="sidebar-desktop"
            >
              <Outlet />
            </div>
          </div>
          {open ? (
  <div className="fixed right-12 top-16 bg-[#131420] p-8 rounded-lg z-50">
    <div className="text-white text-start cursor-pointer py-2 font-semibold hover:text-blue-500" onClick={handleHome}>
      Home
    </div>
    <div className="text-white text-start cursor-pointer py-2 font-semibold hover:text-blue-500" onClick={handleMyOrders}>
      My Orders
    </div>
    {/* <div  onClick={()=>setOpen(false)} className='text-white text-start cursor-pointer py-2 font-semibold'>Profile</div> */}
    <div className="text-white text-start cursor-pointer py-2 font-semibold hover:text-blue-500" onClick={handleLogout}>
      Logout
    </div>
    <button
      onClick={() => setOpen(false)}
      className="absolute top-2 right-2 focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white hover:text-gray-400 transition-colors duration-300"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
) : (
  ""
)}

        </>
      )}
    </div>
  );
};

export default Dashboard;
