import React from "react";
import "../../sass/files/card.scss";
import { HiOutlineCalendar } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
const SWITCHED_TIME=1679759879
const OrderCard = (props) => {
  // const getImage = (createdAt,image) => {
  //  console.log(createdAt,image);

  //   // jb creaetdAt switch wale time se phle hoga to pixel wala url nhi to nya url hoga
  //   const _createdAt = new Date(createdAt);
  //   if(_createdAt.getTime() > SWITCHED_TIME){
    
  //     return `https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`
  //   } else{
  //     return `https://pixelpark-images.s3.amazonaws.com/${image}`
  //     };
  //   }

  const handleCheckout = async () => {
        console.log("Checking payload data:", props.price, props.title, props.emailId);

    const payload = {
      price: Number(props.price) / 2,
      title: props.title,
      email: props.emailId, 
    };

    fetch("https://prod-api.3dspot.io/api/v1/orders/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(payload),
    })
    .then(res => res.json())
    .then(data => {
      props.setClientSecret(data.clientSecret);
      props.setSelectedOrder(props.orderId);
    })
    .catch(error => {
      console.error('Error creating payment intent:', error);
    });
  }
  

  return (
    <div class="card bg-neutral min-w-[250px] max-w-[320px] h-[375px]">
      <div className="grid grid-cols-1">
        <div class="p-3">
          <img
            className="mx-auto w-full h-[170px] object-cover"
            // src = {()=>{console.log(props.image,props.date)
            //   return `https://pixelpark-images.s3.amazonaws.com/${props.image}`
            // }}
            src={`https://project3dspot.s3.ap-south-1.amazonaws.com/${props.image}`}
            alt=""
          />
          <h2 class="card-title py-3">{props.title.length < 20 ? props.title : props.title.substring(0, 19) + "..."}</h2>
          <p className="text-xs flex gap-2 items-center mb-1">
            Created On {props.date?.replace("T", " ").slice(0, -5)}
          </p>
          <p className="text-xs">{props.status.toUpperCase()}</p>
          <h2 class="card-title text-info pt-3">$ {props.price}</h2>
        </div>

        <div className="card-button flex justify-between">
          <div
            className="flex gap-2 items-center"
            onClick={handleCheckout}
          >
            <label htmlFor="my-drawer-4" className="btn btn-ghost">
              View Details
            </label>
          </div>
          {props.isAdmin && (
            <div
              className="pr-4 pt-3 cursor-pointer"
              onClick={() => props.deleteOrder(props.orderId)}
            >
              <MdDeleteOutline className="text-2xl" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
