import React from 'react'
import '../../../../sass/files/family.scss'
import instagram from '../../../../assets/images/homepage/Frame 196.png'
import facebook from '../../../../assets/images/homepage/Frame 198.png'

const Family = () => {

    const openSocial = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div>
            <section className="hero">
                <div className="hero-body">
                    <div className="flex flex-col gap-4 lg:flex-row">
                        <div className="basis-1/2 text-center sm:text-left">
                            <p className="family-title text-center sm:text-left ">
                                Join the Family
                            </p>
                            <p className="family-subtitle mt-6 lg:w-96 sm:w-80 pb-6 ">
                                Learn more about the 3D Spot - or come and chat with others in the community as how and what they are printing with their 3D printers.
                            </p>
                        </div>
                        <div className="basis-1/2">
                            <div className="w-full flex">
                                <div onClick={() => openSocial("https://www.instagram.com/_3dspot/")} className="basis-1/2 flex justify-end">
                                    <img className='cursor-pointer' src={instagram} alt="plc" />
                                </div>
                                <div onClick={() => openSocial("https://www.facebook.com/3dspotted")} className="basis-1/2 flex justify-end">
                                    <img className='cursor-pointer' src={facebook} alt="plc" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Family
