import * as React from "react";
import { useState, useEffect } from "react";
import { useSpinner } from "../../context/Spinner";
import ClipLoader from "react-spinners/ClipLoader";


export default function GlobalSpinner() {
    const spinner = useSpinner();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(spinner.isLoading);
    }, [spinner]);

    return (
        <div className="fixed right-10 bottom-10">
            <ClipLoader color="#FD3DCE" loading={isLoading} size={50} />
        </div>
    );
}