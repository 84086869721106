import React from "react";
import "../../../../sass/files/create.scss";
import logo from "../../../../assets/images/logo/Vector(5).png";
import projectdata from "../../../../json-data/project.json";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate()

  const openTalkToExperts = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <section className="hero create-section" id="show-process">
        <div className="hero-body">
          <div className="flex flex-col gap-12 lg:flex-row">
            <div className="sm:block sm:basis-1/2 flex justify-center items-center">
              <p className="create-main-text text-right lg:text-left">
                Easy <br />
                Simplified <br />
                Process <br />
              </p>
            </div>
            <div className="text-left">
              <img src={logo} alt="plc" />
              <p className="create-header mt-6">Create Your Project</p>
              <div className="mt-6">
                {projectdata.map((project) => {
                  return (
                    <>
                      <div className="project-box p-3">
                        <div className="project-number">
                          <p className="project-number-text">{project.id}</p>
                        </div>
                        <div className="project-description ml-3">
                          <p className="project-title">{project.title}</p>
                          <p className="project-subtitle mt-3">
                            {project.subtitle}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="create-buttons mt-6">
                <button onClick={() => navigate("/signup")} className="secondary-button w-full sm:w-80">
                  Sign up and Start Building
                </button>
                <p className="project-subtitle ml-3 mr-3">or</p>
                <button onClick={() => openTalkToExperts("https://api.whatsapp.com/send?phone=19177643908&text=Hello,%20I%20want%20a%203D%20Model%20to%20be%20made")} className="primary-button w-full  sm:w-60">
                  Talk to our Experts
                </button>
              </div>
              <p className="project-subtitle mt-5">
                Creating your project is quick, easy and completely free!
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Create;
