import React from "react";

const TermsAndConditionsPage = () => {
  return (
    <div className="terms-conditions">
      <h1 className="text-2xl font-bold mb-4 text-center">
        Terms and Conditions
      </h1>
      <ul className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        <li>
          <h3 className="text-lg font-bold mb-2">Payment Terms:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              The client shall make a payment of 50% of the total cost of the
              project before 3D Spot commences work on the project.The remaining
              50% of the payment shall be made upon the final screenshot of the
              model being sent to the client. If the final payment is not
              received within 7 days of the final screenshot being sent, 3D Spot
              reserves the right to withhold the final 3D printable model until
              the payment is made.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Non-Refundable Policy:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              {" "}
              All payments made by the client to 3D Spot are non-refundable.
              Once the client has made a payment, it cannot be refunded.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Intellectual Property:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              The client retains all rights and ownership to any intellectual
              property related to the 3D printable model. 3D Spot will not claim
              any ownership or rights to the model unless and until the client
              refuses to pay the due amount.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Delivery Time:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              3D Spot shall make every effort to deliver the 3D printable model
              within the agreed-upon timeline. However, 3D Spot shall not be
              held responsible for any delays due to unforeseen circumstances
              beyond their control
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Quality of Work:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              3D Spot shall make every effort to ensure that the 3D model meets
              the client's requirements and expectations. If the client is not
              satisfied with the quality of work, 3D Spot shall make reasonable
              efforts to resolve any issues. However, the client shall not be
              entitled to a refund for quality issues.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Confidentiality:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              3D Spot shall maintain the confidentiality of all information
              provided by the client. 3D Spot shall not disclose any information
              to third parties without the client's consent.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Governing Law:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              This agreement shall be governed by and construed in accordance
              with the laws of the jurisdiction in which 3D Spot is located.{" "}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditionsPage;
