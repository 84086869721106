import React from "react";
import "../../../sass/files/subscription.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutModal from "./CheckoutModal";
import { useState } from "react";
import useGetUser from "../../../hooks/queries/useGetUser";
import axios from "axios";
import api from "../../../api/axios"
const card_data = [
  {
    id: 1,
    title: "Character Models",
    description: " Order up to 5 custom human/animal STL per month",
    price: "$400",
    points: [
      "Guaranteed timely delivery to fuel your creativity",
      "Receive a refund if the order gets delayed",
      "Model exclusive for you",
      "24/7 Live Chat Support for immediate assistance and guidance",
    ],
    buttontext: "Subscribe Now",
    monthlyPriceId: "price_1OYBQ8KDWovJoqmyp75Pj5nv",
    yearlyPriceId: "price_1OYBCWKDWovJoqmyDwxwcFJk",
  },
  {
    id: 2,
    title: "Concept Art/Action Figures",
    description: "Order up to 5 custom Action figure/Concept art STL per month",
    price: "$700",
    points: [
      "Guaranteed timely delivery to fuel your creativity",
      "Receive a refund if the order gets delayed",
      "Model exclusive for you",
      "24/7 Live Chat Support for immediate assistance and guidance",
    ],
    buttontext: "Subscribe Now",
    monthlyPriceId: "price_1OYBCRKDWovJoqmyNCXua8Mk",
    yearlyPriceId: "price_1OYBCRKDWovJoqmyzI3BweGh",
  },
  {
    id: 3,
    title: "Vehicle Models",
    description: "Order up to 5 custom vehicle STL per month",
    price: "$600",
    points: [
      "Guaranteed timely delivery to fuel your creativity",
      "Receive a refund if the order gets delayed",
      "Model exclusive for you",
      "24/7 Live Chat Support for immediate assistance and guidance",
    ],
    buttontext: "Subscribe Now",
    monthlyPriceId: "price_1OYBCKKDWovJoqmyZWsGmaeA",
    yearlyPriceId: "price_1OYBCKKDWovJoqmybIQHpT6b",
  },
  // {
  //   id: 4,
  //   title: "General Custom Models",
  //   description: "Order any type of custom STL up to 10 per month",
  //   price: "$800",
  //   points: [
  //     "Guaranteed timely delivery to fuel your creativity",
  //     "Receive a full refund if the delivery is even a minute late",
  //     "Model exclusive for you",
  //     "24/7 Live Chat Support for immediate assistance and guidance",
  //   ],
  //   buttontext: "Subscribe Now",
  // },
];

const yearlyPrices = [
  { originalPrice: "$4200", discountedPrice: "4000" },
  { originalPrice: "$7800", discountedPrice: "$7600" },
  { originalPrice: "$7200", discountedPrice: "$7000" },
  { originalPrice: "$6000", discountedPrice: "$5800" },
];

const Tiers = ({ yearlyBilling }) => {
  const [subscriptionData, setSubscriptionData] = useState();
  const user = useGetUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const stripePromise = loadStripe(
    "pk_live_51MVvSRKDWovJoqmyMgsXs9SYoRaDTuyo4o4gHIoUCSN4yxpaLP3XDaEisFv8Cb6fgT3dHeLLzR33WuYrnZlOiBn700hFF0kBrA"
  );

  const openModal = (data) => {
    setSubscriptionData({
      ...data,
      yearlyBilling,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSubscriptionData(null);
    setIsModalOpen(false);
  };

  const cancelSubscription = async () => {
    try {
      await api.post("orders/unsubscribe", {
        subId: user.data.subscriptionId,
        email: user.data.emailId,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <Elements stripe={stripePromise}>
        <div className="flex justify-center">
          <div className="text-center">
            <div className="h-full">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-12">
                {card_data.map((data, index) => (
                  <div className="card-container" key={index}>
                    <div className="card mem-card w-80 bg-secondary text-white">
                      <div className="card-body border-none mem-card-body font-bold">
                        <h1 className="mb-4 text-2xl font-semibold">
                          {data.title}
                        </h1>
                        <h1 className="mb-4 text-lg font-medium">
                          {data.description}
                        </h1>
                        <h1 className="card-price">
                          {yearlyBilling ? (
                            <span>
                              <span
                                style={{
                                  textDecorationLine: "line-through",
                                  textDecorationStyle: "solid",
                                  fontSize: "1rem",
                                }}
                              >
                                {yearlyPrices[index].originalPrice}
                              </span>{" "}
                              {yearlyPrices[index].discountedPrice}
                            </span>
                          ) : (
                            data.price
                          )}
                        </h1>
                        <div className="h-auto rounded-lg p-4 text-start bg-[#0000004d] border-gradient">
                          {data.points.map((point, idx) => (
                            <div className="py-1 flex" key={idx}>
                              <div>
                                {/* Placeholder text-based icon */}
                                <span className="icon">●</span>
                              </div>
                              <div className="pl-2 text-sm font-medium py-1">
                                {point}
                              </div>
                            </div>
                          ))}
                          <div className="flex justify-center">
                            {user?.data?.subscriptionStatus &&
                            user?.data?.subscriptionPlan == data.id ? (
                              <button
                                onClick={cancelSubscription}
                                className=" unsubscribe-button w-full rounded-lg my-4"
                              >
                                Unsubscribe Now
                              </button>
                            ) : (
                              <button
                                onClick={() => openModal(data)}
                                className="btn button-background w-full rounded-lg my-4"
                              >
                                {data.buttontext}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="card-actions justify-center"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CheckoutModal
          subscriptionData={subscriptionData}
          showModal={isModalOpen}
          handleCloseModal={closeModal}
        />
      </Elements>
    </section>
  );
};

export default Tiers;