import React, { useState, useEffect } from "react";
import "../../sass/files/navbar.scss";
import { NavLink } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import { HiMenuAlt4 } from "react-icons/hi";
import logo from "../../assets/images/logo/grey_compress.png";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);


  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
      console.log("scroll 50 trigger")
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const handleClick = () => {
    setOpen(!open);
    setNavbar(true);
  };

  const closeMenu = () => {
    setOpen(false);
    setNavbar(false);
  };

  return (
    <div className="marque-navbar">
      <div className={navbar ? "navbar-active" : "navbar"}>
        <div className="navbar-contents">
          <NavLink to="/" className="nav-logo">
            <figure className="image nav-image" alt="logo">
              <img
                onClick={() => navigate("/")}
                src={logo}
                className="nav-logo-image md:w-[80px] md:h-[80px] sm:h-[80px]  "
                alt="nav-logo"
              />
            </figure>
          </NavLink>
          <div onClick={handleClick} className={"nav-icon"}>
            {open ? <CgClose /> : <HiMenuAlt4 />}
          </div>
          <ul
            className={open ? "nav-links active" : "nav-links"}
            id={"mobile-nav-links"}
          >
            <li>
              <label className={"navbar-item nav-item-dark"} htmlFor="my-drawer-4">
                Track
              </label>
            </li>
            <li>
              <NavLink
                to="/dashboard/join"
                className={"navbar-item"}
                // activeClassName="active-link"
                onClick={closeMenu}
              >
                Subscription
              </NavLink>
            </li>
            <li>
              <NavHashLink
                smooth spy to="#gallery"
                onClick={closeMenu}
                className="navbar-item nav-item-dark cursor-pointer"
                activeClassName="active-link"
              >
                Gallery
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                smooth spy to="#review"
                onClick={closeMenu}
                className="navbar-item nav-item-dark cursor-pointer"
                activeClassName="active-link"
              >
                Review
              </NavHashLink>
            </li>
            {/* <li>
              <NavHashLink
        
                to="/aboutus"
                onClick={closeMenu}
                className="navbar-item nav-item-dark cursor-pointer"
                activeClassName="active-link"
              >
                About Us
              </NavHashLink>
            </li> */}
            <div className="hamburger-buttons">

              {localStorage.getItem("token") ? (
                <NavLink
                  to="/dashboard/orders"
                  // className={"navbar-item nav-item-dark"}
                  activeClassName="active-link"
                  onClick={closeMenu}
                >
                  <label htmlFor="my-drawer-4" className="btn primary-button w-full">
                    Dashboard
                  </label>
                </NavLink>
              ) : (
                <div>
                  <button onClick={() => navigate("/dashboard/create")} className="btn secondary-button mb-3 w-full">Create Order</button>

                  <NavLink
                    to="/login"
                    className={"navbar-item nav-item-dark "}
                    activeClassName="active-link"
                    onClick={closeMenu}
                  >
                    <label htmlFor="my-drawer-4" className="btn primary-button w-full ">
                      Login
                    </label>
                  </NavLink>
                </div>

              )}
              <p className="nav-content mt-3">
                Creating your project is quick, easy and completely free!
              </p>
              <div className="footer-icons-set">
                <a
                  href="https://twitter.com/threedspot"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons"
                >
                  <FiInstagram style={{ fontSize: "26px" }} />
                </a>
                <a
                  href="https://www.facebook.com/3dspotted"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons ml-5"
                >
                  <FiFacebook style={{ fontSize: "26px" }} />
                </a>
                <a
                  href="https://twitter.com/threedspot"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons ml-5"
                >
                  <FiTwitter style={{ fontSize: "26px" }} />
                </a>
              </div>
            </div>
          </ul>
          <ul className="nav-buttons">
            <li>
              <div className="nav-icons-set">
                {localStorage.getItem("token") ? (
                  <NavLink
                    to="/dashboard/orders"
                    // className={"navbar-item nav-item-dark"}
                    // activeClassName="active-link"
                    onClick={closeMenu}
                  >
                    <label htmlFor="my-drawer-4" className="primary-button">
                      Dashboard
                    </label>
                  </NavLink>
                ) : (
                  <NavLink
                    to="/login"
                    className={"navbar-item nav-item-dark"}
                    activeClassName="active-link"
                    onClick={closeMenu}
                  >
                    <label htmlFor="my-drawer-4" className="primary-button">
                      Login
                    </label>
                  </NavLink>
                )}
                <NavLink
                  to="/dashboard/create"
                  // className={"nav-item-dark"}
                  activeClassName="active-link"
                  onClick={closeMenu}
                >
                  <button className="secondary-button ml-3">
                    Create Order
                  </button>
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;



