import React, { useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import GlobalSpinner from "../../../../components/Spinner";
import { MdMenu } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { HiMenuAlt4 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "../../../../sass/files/dashboard.scss";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";

const Header = ({ setOpen, admin }) => {
  const [ham, setHam] = useState();
  const [navbar, setNavbar] = useState(false);

  const handleClick = () => {
    setHam(!ham);
    setNavbar(true);
  };

  const closeMenu = () => {
    setHam(false);
    setNavbar(false);
  };

  return (
    <div>
      <div className="flex flex-shrink-0 h-20 dashboard-header w-[100vw] shadow-md px-3 lg:px-8 fixed inset-x-0 top-0 items-stretch z-50">
        {/* <div className="pl-72 text-white flex items-center">
        
        </div> */}
        <div className="flex items-center text-white justify-between lg:justify-end flex-grow gap-6">
          {/* <Link to="/dashboard/track">
            <button className="btn btn-ghost">Track Order</button>
          </Link> */}
          {/* <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost flex lg:hidden">
              <MdMenu />
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-4 text-left shadow bg-[#151121] rounded-box w-52">
              <Link to="/dashboard/create" className="py-1 font-semibold ">Create</Link>
              <Link to="/dashboard/orders" className="py-1 font-semibold ">Orders</Link>
              <Link to="/dashboard/settings" className="py-1 font-semibold ">Settings</Link>
              <Link to="/dashboard/join" className="py-1 font-semibold">Patreon</Link>
            </ul>
          </div> */}

          <div onClick={handleClick} className={"nav-icon flex lg:hidden"}>
            {ham ? <CgClose /> : <HiMenuAlt4 />}
          </div>
          <ul
            className={ham ? "nav-links active" : "nav-links"}
            id={"mobile-nav-links"}
          >
            <li>
              {admin ? (
                <NavLink
                  to="/dashboard/users"
                  className={"navbar-item lg:hidden"}
                  // activeClassName="active-link"
                  onClick={closeMenu}
                >
                  Users
                </NavLink>
              ) : (
                <NavLink
                  to="/dashboard/create"
                  className={"navbar-item lg:hidden"}
                  // activeClassName="active-link"
                  onClick={closeMenu}
                >
                  Create
                </NavLink>
              )}
            </li>
            <li>
              {admin ? (
                <NavLink
                  to="/dashboard/Couponcode"
                  className={"navbar-item lg:hidden"}
                  // activeClassName="active-link"
                  onClick={closeMenu}
                >
                  Coupon Codes
                </NavLink>
              ) : null}
            </li>
            <li>
              <Link
                to="/dashboard/orders"
                onClick={closeMenu}
                className="navbar-item lg:hidden"
                activeClassName="active-link"
              >
                Orders
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/settings"
                onClick={closeMenu}
                className="navbar-item lg:hidden"
                activeClassName="active-link"
              >
                Settings
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/join"
                onClick={closeMenu}
                className="navbar-item lg:hidden"
                activeClassName="active-link"
              >
                Subscription
              </Link>
            </li>

            <div className="hamburger-buttons">
              <p className="nav-content mt-3">
                Creating your project is quick, easy and completely free!
              </p>
              <div className="footer-icons-set">
                <a
                  href="https://twitter.com/POWERCITYio"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons"
                >
                  <FiInstagram style={{ fontSize: "26px" }} />
                </a>
                <a
                  href="https://discord.gg/w574jPnQpZ"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons ml-5"
                >
                  <FiFacebook style={{ fontSize: "26px" }} />
                </a>
                <a
                  href="https://t.me/POWERCITYio"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-icons ml-5"
                >
                  <FiTwitter style={{ fontSize: "26px" }} />
                </a>
              </div>
            </div>
          </ul>

          <Link to="/dashboard/create" className="hidden lg:flex">
            <button className="secondary-button">Create Project</button>
          </Link>

          <button onClick={() => setOpen(true)} className="btn btn-ghost">
            <FaRegUserCircle className="h-5 w-auto" />
          </button>
        </div>
        <GlobalSpinner />
      </div>
    </div>
  );
};

export default Header;
