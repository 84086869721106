import React from "react";
import UserCard from "../../../components/cards/UserCard";

const Searched = ({
  orders,
  setSelectedOrder,
  isAdmin,
  deleteOrder,
  setAmount,
  downloadAllUsers,
}) => {
  const handleDownloadUsers = () => {
    downloadAllUsers();
  };
  return (
    <div>
      <div className="w-full grid grid-cols-1 gap-6 py-4">
        <button
          className="md:ml-auto secondary-button download-button sm:ml-auto sm:block"
          onClick={handleDownloadUsers}
        >
          Download All Users
        </button>

        {orders?.map((order) => {
          return (
            <div>
              <UserCard
                isAdmin={isAdmin}
                deleteOrder={deleteOrder}
                // image={order.images[0]}
                firstName={order.firstName}
                lastName={order.lastName}
                emailId={order.emailId}
                date={order.createdAt}
                phone={order.phoneNumber}
                inprogressOrdersCount={order.inprogressOrdersCount}
                completedOrdersCount={order.completedOrdersCount}
                // price={order.price}
                // status={order.status}
                orderId={order._id}
                setSelectedOrder={setSelectedOrder}
                downloadAllUsers={downloadAllUsers}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Searched;
