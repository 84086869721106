import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "../../sass/files/footer.scss";
import logo from "../../assets/images/logo/grey_compress.png";
import vec from "../../assets/images/footer/Group37.svg";
import { Link } from "react-router-dom";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import TermsAndConditionsPage from "../../pages/termCondition/termCondition";
import PrivacyPolicyPage from "../../pages/termCondition/policy";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";

// Style to center the modal content
const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh", // Set the maximum height of the modal content
    overflowY: "auto", // Enable vertical scroll if content exceeds the height
    padding: "20px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    background: "#fff",
  },
};

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#terms") {
      setShowTermsModal(true);
    } else if (hash === "#policy") {
      setShowPolicyModal(true);
    }
  }, [location]);

  const handleOpenTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleOpenPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const handleCloseModal = () => {
    setShowTermsModal(false);
    setShowPolicyModal(false);
    navigate("/");
  };

  return (
    <section className="footer  flex flex-col">
      {/* Rest of the code */}
      <div className="footer-body w-full">
        <figure
          className="image"
          style={{ margin: "auto", marginBottom: "80px" }}
        >
          <img src={vec} alt="footer-plc" />
        </figure>
        <div className="footer-column flex w-full ">
          <div className="basis-1/2 p-0  footer-description-column text-left  sm:test-right">
            <Link to="/" className="nav-logo hidden sm:block">
              <figure class="image mb-4" alt="logo">
                <img
                  src={logo}
                  className="logoimage relative w-[100px] top-[25px]"
                  alt="nav-logo"
                />
              </figure>
            </Link>
          </div>
          <div className="p-0 basis-1/2">
            <div className="lg:text-right sm:text-left ">
              <p className="footer-title text-left sm:text-right">
                Turning image into 3d Model
              </p>
              <p className="footer-subtitle mt-5 text-left sm:text-right">
                High level experience in 3d Printable modeling of any stuff you
                can think of.
              </p>
              <HashLink
                smooth
                to="/#terms"
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenTermsModal();
                }}
              >
                Terms and Conditions
              </HashLink>
              <br />
              <HashLink
                smooth
                to="/#policy"
                className="btn"
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenPolicyModal();
                }}
              >
                Privacy Policy
              </HashLink>
            </div>
          </div>
        </div>

        <br />
        <div className="icons-copyright mt-2 w-full">
          <div className="has-text-centered-mobile">
            <p className="copyrights text-left sm:text-right pt-0 md:pt-6">
              ©2022 3D SPOT. ALL RIGHTS RESESRVED
            </p>
          </div>
          <div className="lg:footer-icons-set flex gap-3  pt-4">
            <a
              href="https://www.instagram.com/_3dspot/"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiInstagram style={{ fontSize: "26px" }} />
            </a>
            <a
              href="https://www.facebook.com/3dspotted/"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiFacebook style={{ fontSize: "26px" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/3dspot/"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiLinkedin style={{ fontSize: "26px" }} />
            </a>
          </div>
        </div>
        <FloatingWhatsApp
          phoneNumber="19177643908"
          accountName="3D Spot"
          allowEsc
          allowClickAway
          notification
          avatar={logo}
          notificationSound
        />
      </div>

      {/* Rest of the code */}
      <ReactModal
        isOpen={showTermsModal}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button
          className="absolute top-4 right-3 fixed bg-gradient-to-br from-transparent to-[#2d1582] text-white rounded-full p-2 hover:from-[#fd004e] hover:to-[#2d1582]"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <TermsAndConditionsPage />
      </ReactModal>

      <ReactModal
        isOpen={showPolicyModal}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button
          className="absolute top-4 right-3 fixed bg-gradient-to-br from-transparent to-[#2d1582] text-white rounded-full p-2 hover:from-[#fd004e] hover:to-[#2d1582]"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <PrivacyPolicyPage />
      </ReactModal>
    </section>
  );
};

export default Footer;
