import './App.scss';
import { PayPalButtons, PayPalScriptProvider, payPalScriptProvider } from '@paypal/react-paypal-js';
// import 'bulma'
// import 'bulma/css/bulma.min.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './pages/homepage/Homepage';
import Dashboard from './pages/dashboard/index'
import Orders from "./pages/orders/index"
import Patreon from './pages/subscription';
import Create from './pages/create';
import Settings from './pages/settings';
import Login from './pages/login';
import Signup from './pages/signup';
import Track from './pages/track';
import Join from './pages/join';
import OrderDetails from './pages/orderDetails/orderDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalSpinner from './components/Spinner';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ForgotPassword from './pages/forgotpassword';
import Users from './pages/users';
import Payment from './pages/payment/Payment';
import Thankyou from './pages/thankyou/thankyou';
import AboutUs from './pages/aboutUs/aboutUs';
import { jwtDecode } from 'jwt-decode'; // You may need to install jwt-decode

const checkTokenExpiry = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('isAdmin');
      window.location.href = '/login'; // Redirect to login
    }
  }
};



function App() {
  const ad = (localStorage.getItem('isAdmin') === 'true');
  const [admin, setAdmin] = useState(ad);
  const [userId, setUserId] = useState()
  const client = "983104398944-4g6a5kfmfgomtk1uvva5rbkok2s8jgs7.apps.googleusercontent.com"

  useEffect(() => {
    checkTokenExpiry();
    const interval = setInterval(checkTokenExpiry, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);
  
  useEffect(() => {
    // console.log(userId, "<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>")
    // setAdmin(localStorage.getItem('isAdmin'))
  }, [userId])
  useEffect(() => {

  }, [admin])

  // useEffect(() => {
  //   setAdmin(localStorage.getItem('isAdmin'))
  // }, [localStorage.getItem('isAdmin')])

  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={client}>
        <PayPalScriptProvider options={{ "client-id": "ATfv3TbImRcjzk-gis2Qf7dJ17BmbMf83ro594Xo3nrSad9RCgHo7T0fbiHtg5XKko_tgNcutsIyEa7k" }}>
          <div className="App">
            <Routes>
              <Route path="/" exact element={<Homepage />} />
              <Route path="/login" exact element={<Login admin={admin} setAdmin={setAdmin} setUserId={setUserId} />} />
              <Route path="/signup" exact element={<Signup />} />
              <Route path="/aboutus" exact element={<AboutUs />} />
              <Route path="/forgotpassword" exact element={<ForgotPassword />} />
                <Route path="/pay/:id" exact element={<Payment />} />

              <Route path="/dashboard" exact element={<Dashboard admin={admin} />} >
                <Route path="orders" element={<Orders admin={admin} userId={userId} />} />
                <Route path="users" element={<Users admin={admin} userId={userId} />} />
                <Route path="patreon" element={<Patreon />} />
                <Route path="create" element={<Create />} />
                <Route path="settings" element={<Settings />} />
                <Route path="join" element={<Join />} />
                <Route path="thankyou/:orderId" element={<Thankyou />} />
                <Route path="orderdetails" element={<OrderDetails />} />
              </Route>
            </Routes>
            <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </PayPalScriptProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
