import React, { useState, useEffect } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import Carousel from "./components/Carousel";
import Footer from "../../components/footer/Footer";
import Tiers from "./components/tiers";
import "../../sass/files/subscription.scss";
const Join = () => {
  const openSocial = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [isActive, setActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setActive(false);
  };
  return (
    <div className="subscription-section ">
      <section className="p-8 text-white text-left pt-20 lg:pt-10   ">
        <div className="flex flex-col lg:flex-row w-full justify-between">
          <p className="text-5xl font-bold mb-6">Join the Family</p>
          <div className="flex gap-3">
            <button
              onClick={() => openSocial("https://www.facebook.com/3dspotted")}
              className="btn btn-ghost text-4xl"
            >
              <FaFacebook />
            </button>
            <button
              onClick={() => openSocial("https://www.instagram.com/_3dspot/")}
              className="btn btn-ghost text-4xl"
            >
              <FaInstagram />
            </button>
          </div>
        </div>
        <div className="w-4/5 lg:w-3/5">
          <p className="text-3xs">Subscribe to save on your monthly orders.</p>
        </div>
        <Carousel />
        <div className="py-10">
          <div className="flex justify-start text-5xl font-bold lg:justify-center">
            Choose Plan
          </div>
          <div className="flex justify-start lg:justify-center text-5xl font-bold">
            That's Right For You
          </div>
        </div>
        <div className="text-left lg:text-center text-[#A6A6A6] font-extrabold mb-12">
          Choose plan that works best for you, feel free to contact us
        </div>

        <div className={`slider-container ${isActive ? "slide-active" : ""}`}>
          <div className="slider-border mt-12 mb-12">
            <button
              className={`slider-option-button ${
                selectedOption === 1 ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(1)}
            >
              Bill Monthly
            </button>
            <button
              className={`slider-option-button ${
                selectedOption === 2 ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(2)}
            >
              Bill Yearly
            </button>
          </div>
          {/* <div className="slider-button" onClick={handleToggle}>
        <div className="slider-text">
          <span className={`slider-option ${isActive ? 'slide-right' : 'slide-left'}`}>
            {selectedOption === 1 ? 'Option 1' : 'Option 2'}
          </span>
        </div>
      </div> */}
        </div>
        <div className="pb-28 pt-10">
          {/* Conditionally render the component based on the selectedOption */}
          {selectedOption === 1 ? (
            <Tiers yearlyBilling={false} />
          ) : (
            <Tiers yearlyBilling={true} />
          )}
        </div>

        <Footer />
      </section>
    </div>
  );
};

export default Join;