import React from "react";
import "../../../sass/files/carousel.scss";
import i1 from "../../../assets/images/carousel/A.webp";
import i2 from "../../../assets/images/carousel/B.jpg";
import i3 from "../../../assets/images/carousel/C.jpeg";

const Carousel = () => {
  return (
    <div className="my-12">
      <div class="carousel-container">
        <input type="radio" name="slider" id="item-1" checked />
        <input type="radio" name="slider" id="item-2" />
        <input type="radio" name="slider" id="item-3" />
        <div class="carousel-cards">
          <label class="carousel-card" for="item-1" id="song-1">
            <img className="carousel-img" src={i1} alt="song" />
          </label>
          <label class="carousel-card" for="item-2" id="song-2">
            <img className="carousel-img" src={i2} alt="song" />
          </label>
          <label class="carousel-card" for="item-3" id="song-3">
            <img className="carousel-img" src={i3} alt="song" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
