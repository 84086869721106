import api from "../../../api/axios";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useSendOTPMutation = () => {
  return useMutation(
    async ({ email, setOtpSent }) => {

      const sendOTPResponse = await api.get(`/users/send-otp/${email}`)
      setOtpSent(true)
      return sendOTPResponse.data




    },
    {
      onSuccess: (sendOTPResponse) => {
        toast.success("OTP sent successfully.", { toastId: "toast-message" })
      },
      onError: (error) => {
        toast.error(error.response.data.message, { toastId: "toast-message" })
      },
    }
  );
};


export default useSendOTPMutation;
