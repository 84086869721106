import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";

const Payment = ({ order, id }) => {
  const stripePromise = loadStripe(
    "pk_live_51MVvSRKDWovJoqmyMgsXs9SYoRaDTuyo4o4gHIoUCSN4yxpaLP3XDaEisFv8Cb6fgT3dHeLLzR33WuYrnZlOiBn700hFF0kBrA"
  );

  const options = {
    clientSecret: id,
    appearance: {
      theme: "night",
      /*...*/
    },
  };

  if (!id) {
    return <Navigate />;
  }

  return (
    <div className="payment">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm secretKey={id} order={order} />
      </Elements>
    </div>
  );
};

export default Payment;

{
  /* */
}
