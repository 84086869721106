import React from "react";
import "../../sass/files/card.scss";
import { HiOutlineCalendar } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import user from "../../assets/images/cards/user.png";
import axios from "axios";

const UserCard = ({
  firstName,
  lastName,
  emailId,
  date,
  inprogressOrdersCount,
  completedOrdersCount,
  password,
  phone,
  orderId,
  setSelectedOrder,
  onDownloadAllUsers,
}) => {
  const handleDownloadUsers = () => {
    onDownloadAllUsers();
  };
  return (
    <div className="card bg-neutral w-full">
      <div className="grid grid-cols-1">
        <div className="p-4 flex gap-3 flex-col lg:flex-row items-center justify-between">
          <div>
            <h2 className="card-title text-info">
              {firstName} {lastName}
            </h2>
            <div className="text-xs  mt-1 space-y-1">
              <div>User Id : {orderId}</div>
              <div>Email: {emailId}</div>
              <div>Phone: {phone}</div>
              <div>Inprogress: {inprogressOrdersCount}</div>
              <div>Completed: {completedOrdersCount}</div>
            </div>
          </div>
          <p className="text-3xs flex gap-2 items-center mb-1">
            Member Since {date?.replace("T", " ").slice(0, -5)}
          </p>
        </div>

        <div className="card-button flex justify-between">
          <div className="flex gap-2 items-center">
            <div
              className="flex gap-2 items-center"
              onClick={() => setSelectedOrder(orderId)}
            >
              {/* <label htmlFor="my-drawer-9" className="btn btn-ghost">
              View Details
            </label> */}
            </div>
          </div>
          {/* {props.isAdmin && (
            <div
              className="pr-4 pt-3"
              onClick={() => props.deleteOrder(props._id)}
            >
              <MdDeleteOutline className="text-2xl" />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
