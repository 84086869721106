import React from 'react'

const AboutUs = () => {
  return (
    <div className="p-8  text-white text-left flex justify-center h-auto overflow-y-auto pb-40">
        <div className=''>
           ABOUT US
        </div>
    </div>
  )
}

export default AboutUs