import React, { useEffect } from "react";
import image1 from "../../../assets/images/cards/Rectangle 29852.png";
import image2 from "../../../assets/images/cards/Rectangle 29852(2).png";
import image3 from "../../../assets/images/cards/Rectangle 29852(3).png";
import image4 from "../../../assets/images/cards/Rectangle 29852(1).png";
import OrderCard from "../../../components/cards/OrderCard";

const Active = ({
  orders,
  setSelectedOrder,
  isAdmin,
  deleteOrder,
  setAmount,
  setClientSecret
}) => {
  // console.log("orders in active page", orders);
  useEffect(() => {}, [orders]);
  return (
    <div>
      <div className="w-full grid grid-cols-1 gap-6 py-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
        {orders
          ?.slice(0)
          .reverse()
          .map((order) => {
            return (
              <div onClick={() => setAmount(order.price)}>
                <OrderCard
                  isAdmin={isAdmin}
                  deleteOrder={deleteOrder}
                  image={order.images[0]}
                  title={order.title}
                  date={order.createdAt}
                  price={order.price}
                  status={order.status}
                  orderId={order._id}
                  setSelectedOrder={setSelectedOrder}
                  setClientSecret={setClientSecret}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Active;