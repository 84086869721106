import React from 'react'
import '../../../../sass/files/gallery.scss'
import Carousel from './component/GalleryCarousel';
import tools from "../../../../assets/images/homepage/Frame 25.png"
import threeds from "../../../../assets/images/galleryicons/Frame 8.svg"
import zbrush from "../../../../assets/images/galleryicons/Frame 9.svg"
import maya from "../../../../assets/images/galleryicons/Frame 10.svg"
import unity from "../../../../assets/images/galleryicons/Frame 11.svg"



import { useRef } from 'react';

const Gallery = () => {
    const ref = useRef()
    return (
        <div>
            <section className="hero gallery-section ">
                <div className="hero-body">
                    <p className="gallery-title">
                        Supported Software
                    </p>
                    <br />
                    <p className="gallery-subtitle pb-14">
                        Industry Workflows, Tools and Software
                    </p>
                    <div className="grid grid-cols-1 gap-4 items-center" id="gallery">
                        <div className='flex justify-center'>
                            <div className='flex justify-between w-[500px]'>
                                <img src={threeds} />
                                <img className="mt-5" src={zbrush} />
                                <img src={maya} />
                                <img src={unity} />
                            </div>
                        </div>
                        <div className='flex items-center flex-col pt-10' >
                            {/* <img src={tools} alt="" /> */}
                            <Carousel />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Gallery
