import React, { useEffect, useState } from "react";
import Active from "./Active";

const Switch = ({
  orders,
  setSelectedOrder,
  isAdmin,
  deleteOrder,
  setAmount,
  setClientSecret
}) => {
  const [selected, setSelected] = useState("active");
  const [completeOrders, setCompleteOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const switch_items = [
    {
      label: "Active",
      option: "active",
    },
    {
      label: "In Progress",
      option: "inprogress",
    },
    {
      label: "Completed",
      option: "completed",
    },
  ];

  useEffect(() => {
    if (orders) {
      setCompleteOrders(orders.filter((order) => order.status === "completed"));
      setInProgressOrders(
        orders.filter((order) => order.status === "inprogress")
      );
      setActiveOrders(orders.filter((order) => order.status !== "completed"));
    }
  }, [orders]);

  return (
    <div class="w-full h-full z-100 flex flex-col">
    <div class="flex sm:justify-start justify-center">
      <div class="flex gap-3">
        {switch_items.map((item) => {
          return (
            <button
              class={`lg:px-4 lg:py-2 sm:px-4 sm:py-2 px-2 py-2 text-sm sm:text-base rounded-3xl ${
                selected === item.option
                  ? "bg-accent text-black"
                  : "text-white bg-transparent"
              }`}
              onClick={() => setSelected(item.option)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
    </div>
    <div class="render-options mt-4 lg:mt-8 sm:justify-center sm:mx-0 mx-auto">
      {selected === "active" && (
        <Active
          setSelectedOrder={setSelectedOrder}
          isAdmin={isAdmin}
          deleteOrder={deleteOrder}
          orders={activeOrders}
          setAmount={setAmount}
          setClientSecret={setClientSecret}
        />
      )}
      {selected === "inprogress" && (
        <Active
          setSelectedOrder={setSelectedOrder}
          isAdmin={isAdmin}
          deleteOrder={deleteOrder}
          orders={inProgressOrders}
          setAmount={setAmount}
          setClientSecret={setClientSecret}
        />
      )}
      {selected === "completed" && (
        <Active
          setSelectedOrder={setSelectedOrder}
          isAdmin={isAdmin}
          deleteOrder={deleteOrder}
          orders={completeOrders}
          setAmount={setAmount}
          setClientSecret={setClientSecret}
        />
      )}
    </div>
  </div>
  
  );
};

export default Switch;