import { useQuery, UseQueryOptions } from "react-query";
import api from "../../api/axios";

export default function useGetUser() {
  return useQuery(
    ["getUser"],
    async () => {
      try {
        const response = await api.get(`/users/`);
        return response.data
      } catch (e) {
        // throwing the error causes query to always be stale
        // this is unintentional, and there doesn't appear to
        // be a way to stop this behaviour. So simply set user
        // to undefined.

        return undefined;
      }
    }
  );
}