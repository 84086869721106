import React from 'react'
import Switch from "../orders/components/Switch"
import Discount from './components/Discount'


const Subsctiption = () => {
    return (
        <section className="">
            <Discount />
            <br />
            <Switch />
        </section>
    )
}

export default Subsctiption
