import React, { useState } from "react";
import { toast } from "react-toastify";
import useSendOTPMutation from "../../hooks/mutations/auth/useSendOTP";
var validator = require("validator");

const EmailVerification = ({ email, setEmail, otpsent, setOtpSent }) => {
  const sendOTPMutation = useSendOTPMutation();

  const handleSendOTP = () => {
    if (!email || !validator.isEmail(email)) {
      toast.error("Please enter a valid email address", {
        toastId: "toast-message",
      });
    } else {
      sendOTPMutation.mutate({
        email: email,
        setOtpSent: setOtpSent,
      });
      
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <span class="text-xl mb-3">Email Address</span>
      <input
        label="Email"
        type="email"
        required
        className="input input-bordered bg-[#1B1C2D] w-full"
        pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
        title="enter valid email"
        autoComplete="off"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <button
        type="submit"
        onClick={handleSendOTP}
        // disabled={loginMutation.isLoading}
        className="secondary-button disabled:bg-opacity-70 w-full mt-6"
      >
        Send OTP
      </button>
    </div>
  );
};

export default EmailVerification;
