import React, { useState } from "react";
import "../../sass/files/homepage.scss";
import main from "../../assets/images/homepage/BG Image.png";
import buttonimage from "../../assets/images/button/Frame 218.svg";
import scroll from "../../assets/images/homepage/scrollimage.svg";
import Gallery from "./components/gallery/Gallery";
import Create from "./components/create/Create";
import Family from "./components/family/Family";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { useNavigate } from "react-router-dom";
import useGetOrder from "../../hooks/queries/useGetOrder";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import api from "../../api/axios";
import logo from "../../assets/images/logo/logowhite.png"
import Faq from "./components/faq/faq";
import { MdClose } from "react-icons/md";
import animation from "../../assets/animation/lotie_v1.json";
import Lottie from "react-lottie";
import animation2 from "../../assets/animation/lf30_editor_bjixuktb.json";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import Review from "./components/review/Review"
import {FloatingWhatsApp} from "react-floating-whatsapp";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 10,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const images = [
  {
    image: main,
  },
  {
    image: main,
  },
  {
    image: main,
  },
];

const TrackingIndex = [
  {
    title: "Order Placed",
    step: "pending",
  },
  {
    title: "Price Quoted",
    step: "reviewed",
  },
  {
    title: "Design In Progress",
    step: "paid",
  },
  {
    title: "Completed",
    step: "completed",
  },
];
const Homepage = () => {
  const navigate = useNavigate();
  const openTalkToExperts = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [oid, setOid] = React.useState("");
  const [order, setOrder] = useState(null);
  const [activeStep, setActiveStep] = useState(-1);
  const handleTrack = async () => {
    try {
      const response = await api.get(`/orders/track/${oid}`);
      setOrder(response.data);
      const index = TrackingIndex.findIndex(
        (item) => item.step === response.data.status
      );
      console.log("activeStep", index);
      setActiveStep(index);
    } catch (err) {
      console.log(err);
      alert("Invalid Order Id");
    }
  };

  return (
    <section id="policy">
      <div className="home-page w-screen max-w-screen">
        <div class="drawer drawer-end h-full w-screen ">
          <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
          <div className="drawer-content w-screen" >
            <label htmlFor="my-drawer-4"></label>
            <Navbar />
            <section className="hero top-section-hero">
              <div className="hero-body">
                <div className="flex justify-center items-center max-w-screen flex-col lg:flex-row">
                  <div className="basis-1/2 text-left">
                    <div className="mt-12">
                      <h1 className="title">
                        We Convert <br />
                        2D images into 3D <br />
                        Models.
                      </h1>
                      <h2 className="subtitle text-4xl">
                        Send an Image and get a 3D Model <br />{" "}
                        <span>ready for 3D Printing</span>
                      </h2>
                      {/* <p className="subtitle mt-6">
                                                You send us 2D Images, we process the image <br />
                                                Design and sculpt a 3D Printable File.
                                            </p> */}

                      <Link className="transparent-button" to="/dashboard/create">
                        <img className="mt-6" src={buttonimage} alt="plc" />
                      </Link>
                      <div className="mt-6 hidden lg:flex w-[80px]">
                        {/* <img className="scroll-image" src={scroll} alt="plc" /> */}
                        <Lottie
                          height={150}
                          width={150}
                          options={{
                            ...defaultOptions,
                            animationData: animation2,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="basis-1/2">
                    {/* <img
                      className="mockup-image relative bottom-[50px]"
                      src={main}
                      alt="placeholder"
                    /> */}
                    <Lottie options={defaultOptions} />
                  </div>
                </div>
                <div className="mt-6 sm:hidden w-[100px]">
                  <Lottie
                    height={100}
                    width={100}
                    options={{ ...defaultOptions, animationData: animation2 }}
                  />
                </div>
              </div>
            </section>
            <Create />
            <Gallery id="gallery" />
            <Family />
     
            <section className="flex justify-center ">
              <div className="hero-body w-full flex justify-center items-center" style={{ paddingTop: '0px' }}>
                <div className="lets-do-it-box w-full">
                  <div className="flex lets-do-it-column justify-between items-center">
                    <div className="lg:text-left sm:text-center">
                      <p className="family-title mb-2">Lets Do it...</p>
                      <p className="family-subtitle mb-3 lg:flex gap-2 ">
                        <strong className="text-[20px]">Design and scuplt</strong>{" "}
                        <p className="text-[20px]">3D Printable File.</p>
                      </p>
                    </div>
                    <div className="">
                      <div className="create-buttons">
                        <button
                          onClick={() => navigate("/signup")}
                          className="secondary-button lg:w-80 w-auto"
                        >
                          Sign up and Start Building
                        </button>
                        <p className="project-subtitle ml-3 mr-3">or</p>
                        <button
                          onClick={() =>
                            openTalkToExperts(
                              "https://api.whatsapp.com/send?phone=918877680694&text=Hello,%20I%20want%20a%203D%20Model%20to%20be%20made"
                            )
                          }
                          className="primary-button lg:w-80 w-auto"
                        >
                          Talk to our Experts
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="hero">
              <Faq />
            </div>
            <Review id="review" />
          </div>
          <div className="drawer-side max-w-screen h-full">
            <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
            <ul className="fixed top-0 right-0 flex-1 p-10 overflow-y-auto w-[1/2] max-w-screen bg-[#06011A] text-left min-h-screen">
              <div className="flex justify-between items-center">
                <div className="text-white font-semibold text-2xl pb-6">
                  Track Order
                </div>
                <label htmlFor="my-drawer-4" className="text-xl text-white">
                  <MdClose />
                </label>
              </div>
              <div className="text-white font-medium text-lg pb-6">
                Order Id
              </div>
              <input
                onChange={(e) => setOid(e.target.value)}
                type="text"
                placeholder="Order Id eg. 897745623.."
                className="input text-white border border-[#363636] w-full max-w-xs bg-[#1B1C2D] mb-6"
              />
              <button
                type="submit"
                className="secondary-button w-40 mt-6 mb-12"
                onClick={handleTrack}
              >
                Track
              </button>
              <div className="text-white font-medium text-lg">
                {order?.title}
              </div>
              {/* <div className="text-[#FBFAFC] text-xs opacity-60">
                Current Status
              </div>
              <div className="text-[#00FDEE] text-base font-normal pb-8">
                {order?.status.toUpperCase()}
              </div> */}
              <ul class="steps steps-vertical">
                {TrackingIndex.map((item, index) => (
                  <li
                    class={`step ${index <= activeStep ? "step-accent text-white" : ""
                      }`}
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </ul>
          </div>
        </div>

      </div>
      {/* FloatingWhatsapp */}
    
      <FloatingWhatsApp
        phoneNumber="19177643908"
        accountName="3D Spot"
        allowEsc
        allowClickAway
        notification
        avatar={logo}
        notificationSound
      />
      <Footer />
    </section>
  );
};

export default Homepage;
