import api from "../../api/axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';


const useAddAddress = () => {
  const navigate = useNavigate();

  return useMutation(
    async ({ label,line1,line2,city,state,country,zipCode }) => {
      const payload = {
        label,line1,line2,city,state,country,zipCode
      };

      const response = await api.post("/users/add-address/", {address:payload});
      console.log("add address Response ye hai", response);
      return response.data;
    },
    {
      onSuccess: (signupResponse) => {
        toast.success("Address Added",{ toastId: "toast-message" })
        window.location.reload()
        console.log("Order created successfully");
      },
    }
  );
};

export default useAddAddress;
