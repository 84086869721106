import React, { useState } from "react";
import loginimage from "../../assets/images/homepage/BG Image.png";
import { AiOutlineLeft } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSignupMutation from "../../hooks/mutations/auth/addUserMutation";
import back from "../../assets/images/login-signup/Mask group(1).png";
import isEmail from "validator/lib/isEmail";
import logo from "../../assets/images/logo/grey_compress.png";
import { useGoogleLogin } from "@react-oauth/google";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import EmailInput from "./components/emailinput";
import NewPassword from "./components/newpassword";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const signupMutation = useSignupMutation();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [phone, setPhone] = useState();
    const [OTP, setOTP] = useState("");
    const [step, setStep] = useState("email");

    const handleSignup = () => {

        if (!email && !isEmail(email)) {
            toast.error("Please Enter a valid email", { toastId: "toast-message" });
        } else if (!password) {
            toast.error("Please Enter Your Password", { toastId: "toast-message" });
        } else if (!phone) {
            toast.error("Please Enter Your Phone Number", {
                toastId: "toast-message",
            });
        } else {
            signupMutation.mutate({
                emailId: email,
                password: password,
                phoneNumber: phone,
            });
        }
    };

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            localStorage.setItem("token", codeResponse.code);
            navigate("/dashboard/create");
            toast.success("Login Succesful", { toastId: "toast-message" });
        },
        onError: (codeResponse) => {
            toast.error("Login failed", { toastId: "toast-message" });
        },

        flow: "auth-code",
    });


    return (
        <div className="w-screen h-screen">
            <div className="grid grid-cols-1 lg:grid-cols-2 h-screen lg:h-full">
                <div className="relative hidden lg:flex">
                    <div className="w-full flex justify-center object-contain">
                        <div className="absolute top-10 left-10">
                            <a
                                className="btn btn-ghost hover:bg-transparent text-white normal-case text-xl"
                                href="/"
                            >
                                <figure className="image nav-image" alt="logo">
                                    <img
                                        onClick={() => navigate("/")}
                                        src={logo}
                                        className="nav-logo-image w-[65px] h-[65px]"
                                        alt="nav-logo"
                                    />
                                </figure>
                            </a>
                        </div>
                        <img
                            src={loginimage}
                            className="w-full object-contain "
                            alt="LoginImage"
                        />
                    </div>
                </div>

                <div className="flex w-full justify-center align-center p-4 md:p-0">
                    <div className="form-container w-full z-10 text-white grid place-items-center justify-items-center">
                        <div className="flex-col place-items-center py-6 lg:w-1/2">
                            <div className="flex justify-between">
                                <button
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                    className=" text-xl font-bold"
                                >
                                    <AiOutlineLeft />
                                </button>
                                <button
                                    onClick={() => {
                                        navigate("/login");
                                    }}
                                    className="btn btn-ghost text-xs font-bold"
                                >
                                    Login
                                </button>
                            </div>
                            <div className="font-semibold text-3xl mb-12 mt-5 text-center">
                                Forgot Password
                            </div>
                            {/* {loginMutation?.error instanceof Error && <p>{loginMutation?.error?.response?.data?.message!}</p>} */}

                            <div className="flex flex-col text-left">
                                {step === "email" && <EmailInput step={step} setStep={setStep} email={email} setEmail={setEmail} />}
                                {/* {step === "password" && <NewPassword step={step} setStep={setStep} password={password} setPassword={setPassword} otp={OTP} setOtp={setOTP} />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

