import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import Switch from "./components/Switch";
import { FiDollarSign } from "react-icons/fi";
import { FiServer } from "react-icons/fi";
import status from "../../assets/images/logo/status.svg";
import image1 from "../../assets/images/cards/Rectangle 29852.png";
import image2 from "../../assets/images/cards/Rectangle 29852(2).png";
import useGetOrder from "../../hooks/queries/useGetOrder";
import useGetAllOrders from "../../hooks/queries/orders/useGetAllOrders";
import useGetMyOrders from "../../hooks/queries/orders/useGetMyOrders";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { AiOutlineSend } from "react-icons/ai";
import api from "../../api/axios";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo/grey_compress.png";
import { BiUser } from "react-icons/bi";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useSpinner } from "../../context/Spinner";
import { BiImageAdd } from "react-icons/bi";
import { saveAs } from "file-saver";
import { FaExternalLinkAlt } from "react-icons/fa";
import JSZip from "jszip";
import Payment from "../payment/Payment";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { debounce } from "lodash"; // Import debounce from lodash or another library
import io from "socket.io-client";

const SWITCHED_TIME = 1679759879;
const STATUS_CODES = {
  pending: 0,
  reviewed: 1,
  paid: 2,
  processing: 3,
  completed: 4,
  cancelled: 5,
};

const OrdersList = ({ admin, userId, props }) => {
  const [clientSecret, setClientSecret] = useState("");
  const paypal = useRef();
  const [orderStatus, setOrderStatus] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [order, setOrder] = useState(null);
  // const [admin, setAdmin] = useState(false)
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [amount, setAmount] = useState();
  const [title, setTitle] = useState("");
  const [orders, setOrders] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [cs, setCs] = useState(false);
  const [csi, setCsi] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const socket = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [typingRole, setTypingRole] = useState(null);
  const [currentlyTyping, setCurrentlyTyping] = useState(false);
  const [couponCode, setCouponCode] = useState(""); // To store the entered coupon code
  const [couponError, setCouponError] = useState(""); // To store and display any coupon error message
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
  const [reviewedOrdersCount, setReviewedOrdersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [inprogressOrdersCount, setInprogressOrdersCount] = useState(null);
  const [completedOrdersCount, setcompletedOrdersCount] = useState(null);

  const navigate = useNavigate();

  // const orders = useGetAllOrders();
  // const myOrders = useGetMyOrders(userId);
  const divRef = useRef(null);
  const divRef2 = useRef(null);
  const imageFileExtentions = [".jpg", ".jpeg", ".png", ".gif"];
  const [imageFile, setImageFile] = useState();
  const [orderDetails, setOrderDetails] = useState(null);
  const { orderId } = useParams();

  useEffect(() => {
    // Socket connection logic

    socket.current = io("https://prod-api.3dspot.io");

    const handleAdminTyping = (data) => {
      if (data.orderId === selectedOrder) {
        setIsTyping(data.isTyping);
        setTypingRole("admin");
      }
    };

    const handleUserTyping = (data) => {
      if (data.orderId === selectedOrder) {
        setIsTyping(data.isTyping);
        setTypingRole("user");
      }
    };

    socket.current.on("adminTyping", handleAdminTyping);
    socket.current.on("userTyping", handleUserTyping);

    // Fetch order details if orderId is available
    // if (orderId) {
    //   const fetchOrderDetails = async () => {
    //     setLoading(true);
    //     try {
    //       const response = await axios.get(`/api/orders/${orderId}`);
    //       setOrderDetails(response.data); // Assuming your API response structure
    //     } catch (error) {
    //       console.error("Failed to fetch order details", error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };

    //   fetchOrderDetails();
    // }

    return () => {
      if (socket.current) {
        socket.current.off("adminTyping", handleAdminTyping);
        socket.current.off("userTyping", handleUserTyping);
        socket.current.disconnect();
      }
    };
  }, [selectedOrder, orderId]); // Add orderId to dependency array

  const handleStartTyping = () => {
    socket.current.emit("startTyping", {
      orderId: selectedOrder,
      userId,
      isAdmin: admin,
    });
  };

  const handleStopTyping = useCallback(
    debounce(() => {
      socket.current.emit("stopTyping", {
        orderId: selectedOrder,
        userId,
        isAdmin: admin,
      });
    }, 2000),
    [selectedOrder, userId, admin]
  );

  // Call this function when the user starts typing
  const handleTyping = (isTyping, isAdmin) => {
    if (isTyping) {
      if (!currentlyTyping) {
        socket.current.emit("startTyping", {
          orderId: selectedOrder,
          userId,
          isAdmin,
        });
        setCurrentlyTyping(true);
      }
      debouncedStopTyping(isAdmin); // This will reset the debounce timer every time the user types
    } else {
      socket.current.emit("stopTyping", {
        orderId: selectedOrder,
        userId,
        isAdmin,
      });
      setCurrentlyTyping(false);
    }
  };

  const debouncedStopTyping = debounce((isAdmin) => {
    // console.log(`debouncedStopTyping called: isAdmin=${isAdmin}`);

    handleTyping(false, isAdmin);
  }, 2000); // Adjust the debounce time as needed

  const handleFileUpload = async (e) => {
    spinner.setLoadingState(true);
    for (const file of e.target.files) {
      if (file.type.match(/image.*/)) {
        setImageFile(file);
        const formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("orderId", order._id);
        formData.append("comment", "");
        formData.append("title", order.title);

        const response = await api.post("/orders/comment", formData);
      }
    }
    fetchOrder(order._id);
    fetchOrder(order.title);
    setCsi(!csi);
    spinner.setLoadingState(false);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [cs]);
  useEffect(() => {
    if (divRef2.current) {
      divRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [csi]);
  const spinner = useSpinner();

  const currency = "USD";
  const updateStatus = async (status, orderId) => {
    try {
      const response = await api.put("/orders/", {
        orderId,
        status,
      });
      console.log(response.data);
      fetchOrders();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // const getImage = (createdAt, image) => {
  //   // jb creaetdAt switch wale time se phle hoga to pixel wala url nhi to nya url hoga
  //   const _createdAt = new Date(createdAt);
  //   if (_createdAt.getTime > SWITCHED_TIME) {
  //     return `https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`;
  //   } else {
  //     return `https://rk-images.s3.amazonaws.com/${image}`;
  //   }
  // };
  const fetchOrders = async () => {
    spinner.setLoadingState(true);
    try {
      const { data } = await api.get("/orders");
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast.error("Failed to load orders.");
    } finally {
      spinner.setLoadingState(false);
    }
  };
  const fetchOrder = useCallback(
    debounce(async (id) => {
      if (!id) return;
      setLoading(true);
      try {
        const response = await api.get(`/orders/details/${id}`);
        const orderData = response.data;
        setOrder(orderData);
        setDiscountApplied(orderData.discountApplied);
        setDiscountedPrice(
          orderData.discountApplied ? orderData.discountedPrice : null
        );
      } catch (error) {
        console.error("Error fetching order details:", error);
        // toast.error("Failed to load order details.");
      } finally {
        setLoading(false);
      }
    }, 1000),
    [] // If there are specific dependencies that affect the debounce function, include them here.
  );

  const deleteOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.delete("/orders/delete/" + id);
      setOrder(response.data);
    }
    toast.success("Order deleted successfully");
    fetchOrders();
    setLoading(false);
  };
  const cancelOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.delete("/orders/cancel/" + id);
      setOrder(response.data);
    }
    toast.success("Order canceled successfully");
    fetchOrders();
    setLoading(false);
  };
  const handleReview = async () => {
    const response = await api.post("/orders/review", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    fetchOrders();
    setPrice();
    // console.log(response.data);
  };
  const handleReview_update_price = async () => {
    const response = await api.post("/orders/update_price", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    fetchOrders();
    setPrice();
    // console.log(response.data);
  };

  const handleApplyDiscount = async () => {
    if (!couponCode) {
      toast.error("Please enter a coupon code.");
      return;
    }

    setIsApplyingDiscount(true);

    try {
      const response = await api.post("/orders/applyDiscount", {
        orderId: order._id,
        couponName: couponCode,
      });

      const updatedOrder = response.data.order; // Assuming this is where updatedOrder is defined

      if (updatedOrder.discountApplied) {
        setOrder(updatedOrder);

        const updatedOrders = orders.map((o) => {
          if (o._id === updatedOrder._id) {
            return updatedOrder;
          }
          return o;
        });
        setOrders(updatedOrders);

        setDiscountApplied(true);
        setDiscountedPrice(updatedOrder.price); // Assuming the updated price is now in `updatedOrder.price`

        toast.success("Discount applied successfully");

        // Call handleCheckout with the updated order's ID and new price
        handleCheckout(updatedOrder._id, updatedOrder.price); // Corrected to pass the updated price
      } else {
        toast.error("Invalid coupon code. Please try again.");
      }
    } catch (error) {
      toast.error(error.response.data.message || "Failed to apply discount");
    } finally {
      setIsApplyingDiscount(false);
    }
  };

  const handleCheckout = async (orderId, price) => {
    try {
      const response = await fetch(
        "https://prod-api.3dspot.io/api/v1/orders/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include other headers as needed, like authorization tokens
          },
          body: JSON.stringify({ orderId, price: price / 2 }), // Use the price directly
        }
      );
      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      }
    } catch (error) {
      console.error("Failed to create payment intent:", error);
    }
  };

  const handleRemoveDiscount = async () => {
    try {
      const response = await api.post("/orders/removeDiscount", {
        orderId: order._id,
      });
      if (response.data.success) {
        toast.success("Discount removed successfully");

        // Assuming the API returns the updated order object without the discount
        const updatedOrder = response.data.order;

        // Update the order state with the updated order details
        setOrder(updatedOrder);

        // If you're maintaining any other state that depends on this order's details, update that as well
        // Here we're updating the orders array to reflect the change in the order's discount status
        const updatedOrders = orders.map((o) => {
          if (o._id === updatedOrder._id) {
            return updatedOrder; // Update the order with the new details
          }
          return o;
        });
        setOrders(updatedOrders); // Update the orders state to trigger re-render of dependent components

        // Update any other relevant states, if necessary
        setDiscountApplied(false);
        setDiscountedPrice(null);
        handleCheckout(updatedOrder._id, updatedOrder.price); // Corrected to pass the updated price
      } else {
        toast.error("Failed to remove discount");
      }
    } catch (error) {
      console.error("Error removing discount:", error);
      toast.error("An error occurred while removing the discount");
    }
  };

  const handleComment = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/orders/comment", {
        orderId: order._id,
        comment: comment,
        title: order.title,
      });
      // console.log(response);
    } catch (error) {
      console.log(error);
    }

    fetchOrder(order._id);
    setComment("");
    // setCs(!cs);
    setCsi(!csi);

    // console.log(response.data);
  };

  const handleFileDownload = async () => {
    const files = [];

    for (const [index, image] of order.images.entries()) {
      const fileExtension = "jpeg";
      const fileName = `${order._id}_${index}.${fileExtension}`;

      try {
        const response = await fetch(
          `https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`
        );
        if (!response.ok) {
          throw new Error("Failed to download file");
        }
        const blob = await response.blob();
        files.push({ blob, fileName });
      } catch (error) {
        console.error(`Failed to download file: ${fileName}`);
      }
    }

    if (files.length === 0) {
      console.error("No files to download");
      return;
    }

    if (files.length === 1) {
      saveAs(files[0].blob, files[0].fileName);
    } else {
      const zip = new JSZip();
      files.forEach(({ blob, fileName }) => {
        zip.file(fileName, blob);
      });
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "images.zip");
        })
        .catch((error) => {
          console.error("Failed to generate zip file:", error);
        });
    }
  };

  const handleFileDownloadChat = async (image) => {
    const fileExtension = "jpeg";
    const fileName = `${image.split("/").pop()}.${fileExtension}`;

    try {
      const response = await fetch(image);
      const blob = await response.blob();
      saveAs(blob, fileName);
    } catch (error) {
      console.error(`Failed to download file: ${fileName}`);
    }
  };

  useEffect(() => {
    const userEmail = order?.contact?.emailId; // Make sure this is how you get the user's email
    if (userEmail) {
      const fetchInprogressOrdersCount = async () => {
        try {
          const { data } = await api.get(
            `/orders/getInprogressOrdersCount/${userEmail}`
          );
          // Or using your api utility if it's defined elsewhere
          // const { data } = await api.get(`/api/orders/getInprogressOrdersCount/${userEmail}`);
          console.log(
            "Fetched inprogress orders count:",
            data.inprogressOrdersCount
          );
          setInprogressOrdersCount(data.inprogressOrdersCount); // Update state with fetched count
          setcompletedOrdersCount(data.completedOrdersCount); // Update state with fetched count
        } catch (error) {
          console.error("Failed to fetch inprogress orders count:", error);
        }
      };

      fetchInprogressOrdersCount();
    }
  }, [order?.contact?.emailId]);

  useEffect(() => {
    fetchOrder(selectedOrder);
  }, [selectedOrder]);
  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchOrder(selectedOrder);
    }, 1000 * 5); // in milliseconds
    return () => clearInterval(intervalId);
  });

  // Search Orders
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchUsers, setSearchUsers] = useState([]);
  const HandleSearchUsers = async (searchtext) => {
    try {
      // console.log("Searching for:", searchtext);

      // Make sure searchText is properly encoded for the URL
      const encodedSearchText = encodeURIComponent(searchtext);

      const response = await api.get(
        `/orders/search?keyword=${encodedSearchText}`
      );
      console.log("Response from API:", response.data);

      setSearchUsers(response.data);
    } catch (error) {
      console.error("Error in HandleSearchUsers:", error);
    }
  };

  const removeQueryParams = () => {
    const param = searchParams.get("q");
    if (param) {
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    HandleSearchUsers(" ");
  }, [searchParams.length == 0]);

  useEffect(() => {
    HandleSearchUsers("");
    // console.log(searchParams.length, "search param length")
  }, []);

  useEffect(() => {
    removeQueryParams();
  }, [searchParams.length == 0]);

  useEffect(() => {
    const param = searchParams.get("q");
    // console.log("Param to searc in api", param)
    HandleSearchUsers(param);
  }, [searchParams]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      divRef.current?.scrollTo(0, divRef.current?.scrollHeight);
    }
  }, [order?.comments, shouldScrollToBottom]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = divRef.current;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setShouldScrollToBottom(isAtBottom);
  };
  const planMapping = {
    1: "Character Models",
    2: "Concept Art/Action Figures",
    3: "Vehicle Models",
  };
  const subscriptionPlanString = planMapping[order?.contact?.subscriptionPlan];

  return (
    <div className="drawer drawer-end w-full min-h-screen">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-8 lg:pr-0 w-full h-full">
        <section className="text-white text-left">
          <div className="flex flex-col lg:flex-row justify-between items-center w-full">
            {admin ? (
              <p className="text-5xl font-bold mb-6"></p>
            ) : (
              <p className="text-5xl font-bold mb-6">My Orders</p>
            )}
            <form className="form-control" onSubmit={HandleSearchUsers}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  placeholder="Search for orders"
                  className="input input-bordered bg-[#1B1C2D] w-60"
                  onChange={(e) => setSearchParams({ q: e.target.value })}
                />
                <button className="btn btn-square" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <FloatingWhatsApp
            phoneNumber="19177643908"
            accountName="3D Spot"
            allowEsc
            allowClickAway
            notification
            avatar={logo}
            notificationSound
            style={{ color: "black" }}
          />
          <Switch
            orders={searchParams.get("q") ? searchUsers : orders}
            setSelectedOrder={setSelectedOrder}
            isAdmin={admin}
            deleteOrder={deleteOrder}
            setAmount={setAmount}
            setClientSecret={setClientSecret}
          />
        </section>
      </div>

      <div className="drawer-side">
        <label htmlFor="my-drawer-4" className="drawer-overlay"></label>

        <ul className="menu example fixed top-0 right-0 flex-1  scrollbar-hide p-4 mt-16 pb-16 w-11/12 text-base-content bg-[#06011A] lg:w-1/3 sidebar-gradient">
          {/* <!-- Sidebar content here --> */}
          <div className="overflow-y-scroll w-full scrollbar-hide">
            <button
              className="absolute top-4 right-3 fixed bg-gradient-to-br from-transparent to-[#2d1582] text-white rounded-full p-2 hover:from-[#fd004e] hover:to-[#2d1582]"
              onClick={() => {
                document.getElementById("my-drawer-4").checked = false;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="flex flex-row items-start text-base font-semibold text-[#FBFAFC] pb-4">
              <div className="pr-2">Title : </div>
              <div className="text-base text-white opacity-60 pl-2">
                {order?.title}
              </div>
            </div>
            <div className="flex flex-col items-start text-base font-semibold text-[#FBFAFC] pb-4">
              <div>Tracking Id:</div>
              <div className="text-base text-white opacity-60">
                {order?._id}
              </div>
            </div>

            {/* <div className="flex items-start text-base font-semibold text-[#FBFAFC] pb-4">
            {order?._id}
          </div> */}

            <div className="pb-6 flex justify-between">
              <div className="flex flex-col">
                <div className="flex items-start pb-1 text-[#FBFAFC] text-opacity-60">
                  Price
                </div>
                <div className="flex items-start ">
                  <FiDollarSign className="text-[#FBFAFC] text-opacity-60" />
                  <div className="flex items-start text-white relative bottom-[3px]">
                    {order?.price ? order.price : "Not Quoted"}
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col">
                  <div className="flex items-start pb-2">
                    <FiServer className="text-[#FBFAFC] text-opacity-60" />
                  </div>
                  <div className="flex items-start text-[#FBFAFC] text-opacity-60">
                    File Size
                  </div>
                  <div className="flex items-start text-white">25 Mb</div>
                </div> */}
              <div>
                <div className="flex items-start pb-1 text-[#FBFAFC] text-opacity-60">
                  Status
                </div>
                <div className="flex items-start ">
                  <img src={status} />
                  <div className="flex items-start text-[#00FDEE] pl-2 relative bottom-[3px]">
                    {order?.status}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="flex flex-col items-start text-base font-semibold text-[#FBFAFC] pb-4">
              {admin && (
                <button
                  onClick={handleFileDownload}
                  className="secondary-button w-full md:w-64 my-1"
                >
                  Download Images
                </button>
              )}
            </div>

            <div className="flex items-start text-base font-semibold text-[#FBFAFC] pb-4">
              Uploaded Images
            </div>

            <div className="grid grid-cols-2 gap-2 pb-2">
              {order?.images?.map((image) => {
                return (
                  <div>
                    <a
                      href={`https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="order-img"
                        className="rounded-lg w-[250px] h-[150px] object-cover"
                        src={`https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`}
                      />
                    </a>
                  </div>
                );
              })}
            </div>

            <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
              Notes
            </div>
            <div className="flex items-start text-[#FBFAFC] text-opacity-60 pb-4">
              {order?.notes}
            </div>
            {admin && (
              <>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Contact information
                </div>

                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  <div>
                    {order?.contact?.firstName + " " + order?.contact?.lastName}
                    (
                    {order?.contact?.subscriptionStatus
                      ? "Subscribed to "
                      : "Not Subscribed"}
                    {subscriptionPlanString})
                  </div>
                  <div>{order?.contact?.emailId}</div>
                  <div>{order?.contact?.phoneNumber}</div>
                  <div>Inprogress: {inprogressOrdersCount}</div>
                  <div>Completed: {completedOrdersCount}</div>
                </div>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Size
                </div>
                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  {order?.size}
                </div>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Address To Ship
                </div>
                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  {order?.addressToShip ? (
                    <>
                      <div>{order?.addressToShip.line1}</div>
                      <div>{order?.addressToShip.line2}</div>
                      <div>
                        {order?.addressToShip.city}-{order?.addressToShip.state}{" "}
                        : {order?.addressToShip.zipCode}
                      </div>
                      <div>{order?.addressToShip.country}</div>
                    </>
                  ) : (
                    "No address to ship"
                  )}
                </div>
              </>
            )}
            {admin && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Review
                </div>
                <div className="collapse-content ">
                  {order?.status === "pending" ? (
                    <div>
                      {admin ? (
                        <div>
                          {/* Render the "Update" section */}
                          <div className="flex items-start text-[#FBFAFC] text-opacity-60">
                            Price
                          </div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="input w-full max-w-xs"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          <button
                            className="secondary-button w-full md:w-64 my-4"
                            onClick={(event) => {
                              if (!price || parseInt(price) < 1) {
                                toast.error("Please enter a valid amount", {
                                  toastId: "toast-message",
                                });
                              } else {
                                handleReview(event).then(() => {
                                  toast.success(
                                    "Update Successful. Please wait",
                                    {
                                      toastId: "toast-message",
                                    }
                                  );
                                });
                              }
                            }}
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    // Conditionally render the "New Price Set" section only when the order status is "reviewed"
                    order?.status === "reviewed" && (
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Updated Price"
                          className="input w-full max-w-xs"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                        <button
                          className="secondary-button w-full md:w-64 my-4"
                          onClick={(event) => {
                            // Display a confirmation alert
                            const isConfirmed = window.confirm(
                              "Are you sure you want to update the price?"
                            );

                            if (isConfirmed) {
                              if (!price || parseInt(price) < 1) {
                                toast.error("Please enter a valid amount", {
                                  toastId: "toast-message",
                                });
                              } else {
                                handleReview_update_price(event).then(() => {
                                  toast.success(
                                    "Update Successful. Please wait",
                                    {
                                      toastId: "toast-message",
                                    }
                                  );
                                  setPrice("");
                                });
                              }
                            } else {
                              // User clicked "Cancel" in the confirmation dialog, do nothing
                            }
                          }}
                        >
                          New Price Set
                        </button>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            {!admin && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Review
                </div>
                <div className="collapse-content ">
                  {order?.status === "pending" ? (
                    <span className="text-white">You will be quoted soon.</span>
                  ) : (
                    <span className="text-white">Reviewed</span>
                  )}
                </div>
              </div>
            )}
            <div
              tabIndex={0}
              className="collapse collapse-arrow bg-[#1B1C2D] rounded-box mb-2"
            >
              <input type="checkbox" className="peer" />
              <div className="collapse-title text-xl font-medium text-white flex flex-start">
                Payment
              </div>
              <div className="collapse-content">
                {/* Conditional rendering for coupon code input and apply discount button */}
                {!admin && order?.status === "reviewed" && (
                  <div>
                    <input
                      type="text"
                      placeholder={
                        discountApplied
                          ? "Coupon code applied"
                          : "Enter Coupon Code"
                      }
                      value={couponCode}
                      className="input w-full max-w-xs"
                      onChange={(e) => setCouponCode(e.target.value)}
                      disabled={discountApplied}
                    />

                    {!discountApplied && (
                      <button
                        className="secondary-button w-full md:w-64 my-4"
                        onClick={handleApplyDiscount}
                      >
                        Apply Discount
                      </button>
                    )}

                    {discountApplied && (
                      <button
                        onClick={handleRemoveDiscount}
                        className="btn btn-error w-full md:w-64 my-4"
                      >
                        Remove Discount
                      </button>
                    )}
                  </div>
                )}

                {STATUS_CODES[order?.status] < 2 ? (
                  <span className="text-white">
                    <p className="text-left">
                      Deposit{" "}
                      <span className="font-bold mr-1">${order.price / 2}</span>
                      to get started
                    </p>

                    <br />
                    {admin ? (
                      <button className="secondary-button w-full md:w-64 my-4">
                        Send Notification
                      </button>
                    ) : (
                      <Payment order={order} id={clientSecret} />
                    )}
                  </span>
                ) : (
                  <p className="text-white font-bold">Half Payment Received</p>
                )}
              </div>
            </div>
            <div
              tabIndex={0}
              className="collapse bg-[#1B1C2D] rounded-box mb-2"
            >
              <input type="checkbox" className="peer" />

              <div className="collapse-title text-xl font-medium text-white flex flex-start mr-4">
                Chat
                <div className="text-sm flex flex-start items-center  ml-2">
                  {isTyping && typingRole && (
                    <div className="typing-indicator">
                      {typingRole === "admin"
                        ? "(Admin is typing)"
                        : "(User is typing)"}
                    </div>
                  )}
                  {/* The input field and other JSX */}
                </div>
              </div>

              <div>
                <div
                  className="max-h-[250px] overflow-y-scroll"
                  ref={divRef}
                  onScroll={handleScroll}
                >
                  {order?.comments?.map((comment) => {
                    return (
                      <div
                        className={
                          comment.isAdmin
                            ? "flex justify-start"
                            : "flex justify-end"
                        }
                        key={comment.id}
                      >
                        {comment.isAdmin ? (
                          <div className="speech-bubble-left flex gap-3 justify-start items-center text-[#FBFAFC] text-opacity-60 pb-4">
                            <div className="w-[30px] bg-black rounded-full ring ring-primary ring-offset-black ring-offset-1">
                              <img
                                alt="plc"
                                className="rounded-full w-[30px]"
                                src={logo}
                              />
                            </div>
                            {comment?.message.slice(0, 6) === "https:" ? (
                              <div className="relative flex items-center">
                                <a
                                  href={comment.message}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="object-contain w-[150px] h-[150px]"
                                    src={comment.message}
                                    alt="loading"
                                  />
                                  <div className="absolute top-0 right-0 bg-black bg-opacity-75 flex items-center justify-center h-full w-full">
                                    <FaExternalLinkAlt size={20} color="#fff" />
                                  </div>
                                </a>
                              </div>
                            ) : (
                              <div>{comment.message}</div>
                            )}
                          </div>
                        ) : (
                          <div className="speech-bubble-right flex gap-3 justify-end items-center text-[#FBFAFC] text-opacity-60">
                            {comment?.message.slice(0, 6) === "https:" ? (
                              <div className="relative flex items-center">
                                <a
                                  href={comment.message}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="object-contain w-[150px] h-[150px]"
                                    src={comment.message}
                                    alt="loading"
                                  />
                                  <div className="absolute top-0 right-0 bg-black bg-opacity-75 flex items-center justify-center h-full w-full">
                                    <FaExternalLinkAlt size={20} color="#fff" />
                                  </div>
                                </a>
                              </div>
                            ) : (
                              <div>{comment.message}</div>
                            )}

                            <div className="w-[30px] h-[30px] flex items-center bg-black rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                              <BiUser className="rounded-full text-xl w-[30px]" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="speech-bubble my-3 flex items-center">
                  <form
                    className="w-full"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (comment && comment.length > 0) {
                        handleComment(e);
                      }
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Enter Comment"
                      className="input w-full focus:border-0 text-white bg-transparent"
                      onChange={(e) => {
                        setComment(e.target.value);
                        handleStartTyping();
                        handleStopTyping();
                      }}
                      value={comment}
                    />
                  </form>
                  <label
                    className="flex h-full text-xl text-white justify-center items-center cursor-pointer pr-3"
                    htmlFor="dropzone-file"
                  >
                    <BiImageAdd />

                    <input
                      className="hidden"
                      id="dropzone-file"
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                      accept={imageFileExtentions}
                    />
                  </label>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (comment && comment.length > 0) {
                        handleComment(e);
                      }
                    }}
                    className="h-full text-xl justify-center items-center text-white pr-3"
                  >
                    <AiOutlineSend />
                  </button>
                </div>
                <div />
              </div>
            </div>
            {admin && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Status Updates
                </div>
                <div className="collapse-content">
                  <button
                    className="secondary-button w-full md:w-64 my-4 mx-auto"
                    onClick={() => {
                      updateStatus("inprogress", order._id);

                      toast.success("Marked as in progress");
                    }}
                  >
                    Mark in progress
                  </button>
                  <button
                    className="secondary-button w-full md:w-64  my-4 mx-auto"
                    onClick={() => {
                      updateStatus("completed", order._id).then(() => {
                        setOrders((prevOrders) => {
                          const updatedOrders = prevOrders.filter(
                            (o) => o._id !== order._id
                          );
                          return updatedOrders;
                        });
                        toast.success("Marked as completed");
                      });
                    }}
                  >
                    Mark as completed
                  </button>
                  <button
                    className="secondary-button w-full md:w-64  my-4 mx-auto"
                    onClick={() => cancelOrder(order._id)}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            )}
            {admin && order?.addressToShip != "undefined" && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Shipping
                </div>
                <div className="collapse-content pt-2">
                  {" "}
                  <button
                    className="secondary-button w-full md:w-64 my-4 mx-auto"
                    onClick={() => {
                      updateStatus("cancelled");
                    }}
                  >
                    Mark as shipped
                  </button>
                </div>
              </div>
            )}
            <div />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default OrdersList;