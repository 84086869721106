import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import api from "../../api/axios";
import { toast } from "react-toastify";

const CheckoutForm = ({ secretKey, orderId, order }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardErr, setCardErr] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setClientSecret(secretKey);
  }, [secretKey]);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!!");
          handlePaymentSuccess();
          break;
        case "processing":
          setCardErr(true);
          setMessage("Your payment is processing");
          break;
        case "requires_payment_method":
          setCardErr(true);
          setMessage("Your payment is not successful");
          break;
        default:
          setCardErr(true);
          setMessage("Something went wrong");
          break;
      }
    });
  }, [stripe, clientSecret]);

  const handlePaymentSuccess = async () => {
    try {
      await api.post("https://prod-api.3dspot.io/api/v1orders/payment", {
        orderId: order._id,
        paymentId: "",
      });
      // toast.success("Payment Successful");
    } catch (error) {
      console.log(error);
      toast.error("Error occurred while processing the payment");
    }
  };

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.create("payment", {
        paymentMethodOrder: [
          "apple_pay",
          "google_pay",
          "card",
          "klarna",
          // "cashapp",
        ],
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
        business: {
          name: "Test Google",
        },
        wallets: {
          applePay: "auto",
          googlePay: "auto",
        },
      });
      paymentElement.mount("#payment-element");
    }
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://3dspot.io/dashboard/thankyou/${order._id}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error");
    }
    setIsLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="payment-form">
        <div id="payment-element"></div>
        <button
          className="secondary-button w-full md:w-64 my-4"
          type="submit"
          disabled={isLoading || !stripe || !elements}
        >
          {isLoading ? "Loading...." : "Pay Now"}
        </button>
      </form>
    </>
  );
};

export default CheckoutForm;