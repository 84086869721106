import React, { useState } from "react";
import loginimage from "../../assets/images/homepage/BG Image.png";
import { AiOutlineLeft } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useSignupMutation from "../../hooks/mutations/auth/addUserMutation";
import back from "../../assets/images/login-signup/Mask group(1).png";
import isEmail from "validator/lib/isEmail";
import logo from "../../assets/images/logo/grey_compress.png";
import { useGoogleLogin } from "@react-oauth/google";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import EmailVerification from "./emailverification";
import OTPInput from "otp-input-react";
var validator = require("validator");

const Signup = () => {
  const navigate = useNavigate();
  const signupMutation = useSignupMutation();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [phone, setPhone] = useState();
  const [otpsent, setOtpSent] = useState(false);
  const [OTP, setOTP] = useState();

  const handleSignup = () => {
    // console.log(isValidPhoneNumber(phone), "possibility Check", phone)
    if (!firstName) {
      toast.error("Please Enter Your First Name", { toastId: "toast-message" });
    } else if (!lastName) {
      toast.error("Please Enter Your Last Name", { toastId: "toast-message" });
    } else if (!email || !validator.isEmail(email)) {
      toast.error("Please Enter a valid email", { toastId: "toast-message" });
    } else if (!password) {
      toast.error("Please Enter Your Password", { toastId: "toast-message" });
    } else if (!phone || !isValidPhoneNumber(phone)) {
      toast.error("Please Enter a valid Phone Number", {
        toastId: "toast-message",
      });
    } else if (!OTP) {
      toast.error("Please Enter OTP", {
        toastId: "toast-message",
      });
    } else {
      signupMutation.mutate({
        firstName: firstName,
        lastName: lastName,
        emailId: email,
        password: password,
        phoneNumber: phone,
        otp: OTP,
      });
    }
  };
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("token", codeResponse.code);
      navigate("/dashboard/create");
      toast.success("Login Succesful", { toastId: "toast-message" });
    },
    onError: (codeResponse) => {
      toast.error("Login failed", { toastId: "toast-message" });
    },

    flow: "auth-code",
  });

  return (
    <div className="w-screen h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 h-screen lg:h-full">
        <div className="relative hidden lg:flex">
          <div className="w-full flex justify-center object-contain">
            <div className="absolute top-10 left-10">
              <a
                className="btn btn-ghost hover:bg-transparent text-white normal-case text-xl"
                href="/"
              >
                <figure className="image nav-image" alt="logo">
                  <img
                    onClick={() => navigate("/")}
                    src={logo}
                    className="nav-logo-image w-[65px] h-[65px]"
                    alt="nav-logo"
                  />
                </figure>
              </a>
            </div>
            <img
              src={loginimage}
              className="w-full object-contain "
              alt="LoginImage"
            />
          </div>
        </div>

        <div className="flex w-full justify-center align-center p-4 md:p-0">
          <div className="form-container w-full z-10 text-white grid place-items-center justify-items-center">
            <div className="flex-col place-items-center py-6 lg:w-1/2">
              <div className="flex justify-between">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className=" text-xl font-bold"
                >
                  <AiOutlineLeft />
                </button>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="btn btn-ghost text-xs font-bold"
                >
                  Login
                </button>
              </div>
              <div className="font-semibold text-3xl mb-12 mt-5 text-center">
                Sign Up
              </div>
              {/* {loginMutation?.error instanceof Error && <p>{loginMutation?.error?.response?.data?.message!}</p>} */}

              <div className="flex flex-col text-left">
                {!otpsent ? (
                  <EmailVerification
                    email={email}
                    setEmail={setEmail}
                    otpsent={otpsent}
                    setOtpSent={setOtpSent}
                  />
                ) : (
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3 flex-col lg:flex-row">
                      <div className="flex flex-col">
                        <span class="text-xl mb-3">First Name</span>
                        <input
                          label="FName"
                          className="input input-bordered bg-[#1B1C2D] w-full"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="flex flex-col">
                        <span class="text-xl mb-3">Last Name</span>
                        <input
                          label="LName"
                          type="text"
                          className="input input-bordered bg-[#1B1C2D] w-full"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* <span class="text-xl my-3">Email Id</span>
                <input
                  label="Email"
                  type="email"
                  required
                  className="input input-bordered bg-[#1B1C2D] w-full"
                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                  title="enter valid email"
                  autoComplete="off"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                /> */}
                    <span class="text-xl">Password</span>
                    <input
                      label="Password"
                      type="password"
                      className="input input-bordered bg-[#1B1C2D] w-full"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <span class="text-xl">Phone Number</span>
                    <PhoneInput
                      className="input input-bordered bg-[#1B1C2D] w-full"
                      placeholder="Enter phone number"
                      value={phone}
                      label="FName"
                      limitMaxLength={true}
                      onChange={setPhone}
                      textInputStyle={{ background: "#000" }}
                      inputStyle={{
                        background: "lightblue",
                      }}
                    />
                    <span class="text-xl">Enter OTP sent on email</span>
                    <OTPInput
                      value={OTP}
                      inputStyles={{
                        background: "#1B1C2D",
                        border: "none",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "20px",
                        height: "40px",
                        width: "40px",
                      }}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                    />
                    <button
                      type="submit"
                      onClick={handleSignup}
                      // disabled={loginMutation.isLoading}
                      className="secondary-button disabled:bg-opacity-70 w-full mt-6"
                    >
                      {signupMutation.isLoading ? "Signing Up" : "Sign Up"}
                    </button>
                  </div>
                )}
                {/* <input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  label="FName"
                  className="input input-bordered bg-[#1B1C2D] w-full"
                /> */}
              </div>
              {/* <div className="">
                <button
                  onClick={login}
                  className="block w-full border-2  text-black  rounded-lg p-2 my-3 bg-white disabled:bg-opacity-70 hover:bg-gray-200"
                >
                  <span className="text-sm sm:text-base">
                    <FcGoogle className="inline mx-4 scale-150" />
                    Continue With Google
                  </span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
