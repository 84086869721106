import api from "../../../api/axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

export const useUpdateProfileMutation = () => {
  return useMutation(async ({ firstName, lastName, emailId, phoneNumber }) => {
    const UpdateAccountInformation = await api.post("/users/settings", {
      firstName,
      lastName,
      emailId,
      phoneNumber,
    });
    console.log(UpdateAccountInformation);
    const { _id } = UpdateAccountInformation.data.data;
    if (!_id) {
      toast.error("Update failed", { toastId: "toast-message" })
    }
    else {

    }
    return UpdateAccountInformation;
  },
    {
      onSuccess: (UpdateAccountInformation) => {
        toast.success("Update Successful", { toastId: "toast-message" })
        return UpdateAccountInformation.data;
      },
      onError: (error) => {
        toast.error("Update failed", { toastId: "toast-message" })
      },
    }

  );
};

