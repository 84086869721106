import React, { useEffect, useState } from "react";
import useGetUser from "../../hooks/queries/useGetUser";
import AddAddress from "../create/components/AddAddress";
import { useUpdateProfileMutation } from "../../hooks/mutations/account/useProfileSettings";
import { useSpinner } from "../../context/Spinner";


const plans = {
  1: "Character Models",
  2: "Concept Art/Action Figures",
  3: "Vehicle Models",
};

const Settings = () => {
  const [addressArray, setAddressArray] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [emailId, setEmailId] = useState();
  const [password, setPassword] = useState();
  const updateProfileMutation = useUpdateProfileMutation();
  const spinner = useSpinner();
  

  const user = useGetUser();
  console.log(user);
  useEffect(() => {
    if (user.data) {
      setAddressArray(user.data.addresses);
      setEnabled(true);
    }
  }, []);
  useEffect(() => {
    if (user) {
      setFirstName(user?.data?.firstName);
      setLastName(user?.data?.lastName);
      setPhoneNumber(user?.data?.phoneNumber);
      setEmailId(user?.data?.emailId);
      setPassword(user?.data?.password);
    }
  }, [user.data]);

  const HandleUpdateProfile = async () => {
    spinner.setLoadingState(true);
    const response = await updateProfileMutation.mutate({
      firstName: firstName,
      lastName: lastName,
      emailId: emailId,
      phoneNumber: phoneNumber,
    });
    spinner.setLoadingState(false);
  };

  return (
    <section className="p-8 pb-2 text-white text-left w-full  mx-auto h-full lg:w-1/2">
      <p className="text-5xl font-bold mb-6">Settings</p>
      <div className="flex">
        <div className="flex flex-col">
          <div className="flex flex-row gap-3 w-full lg:w-96">
            <div className="flex flex-col gap-3">
              <span class="text-xl mb-3 mt-6">First Name</span>
              <input
                label="FName"
                className="input input-bordered bg-[#1B1C2D] w-full lg:w-48"
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                value={firstName}
              />
            </div>
            <div className="flex flex-col gap-3">
              <span class="text-xl mb-3 mt-6">Last Name</span>
              <input
                label="LName"
                className="input input-bordered bg-[#1B1C2D] w-full lg:w-48"
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                value={lastName}
              />
            </div>
          </div>
          <span class="text-xl mb-3 mt-6">Phone Number</span>
          <input
            label="Email"
            className="input input-bordered bg-[#1B1C2D] w-full lg:w-96"
            placeholder="Phone Number"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            value={phoneNumber}
          />
          <span class="text-xl mb-3 mt-6">Email Id</span>
          <input
            label="Phone"
            className="input input-bordered bg-[#1B1C2D] w-full lg:w-96"
            placeholder="Email Id"
            onChange={(e) => {
              setEmailId(e.target.value);
            }}
            value={emailId}
          />

          {user?.data?.subscriptionStatus ? (
            <>
              <span className="text-xl mb-3 mt-6">Subscription</span>
              {user?.data?.subscriptionStatus ? "Enabled" : "Disabled"}

              <span className="text-xl mb-3 mt-6">Plan Activated</span>
              {user?.data?.subscriptionPlan
                ? plans[user.data.subscriptionPlan] || "Plan Not Found"
                : "Not Activated"}

              <span className="text-xl mb-3 mt-6">Plan Started</span>
              {`${new Date(user?.data?.startDate * 1000)
                .getUTCDate()
                .toString()
                .padStart(2, "0")}/` +
                `${(new Date(user?.data?.startDate * 1000).getUTCMonth() + 1)
                  .toString()
                  .padStart(2, "0")}/` +
                `${new Date(user?.data?.startDate * 1000).getUTCFullYear()}`}

              <span className="text-xl mb-3 mt-6">Plan Ends on</span>
              {`${new Date(user?.data?.endDate * 1000)
                .getUTCDate()
                .toString()
                .padStart(2, "0")}/` +
                `${(new Date(user?.data?.endDate * 1000).getUTCMonth() + 1)
                  .toString()
                  .padStart(2, "0")}/` +
                `${new Date(user?.data?.endDate * 1000).getUTCFullYear()}`}
            </>
          ) : (
            <>
              <span className="text-xl mb-3 mt-6">Subscription</span>
              Disabled
            </>
          )}

          {/* <span class="text-xl mb-3 mt-6">Password</span>
        <input
          label="Password"
          className="input input-bordered bg-[#1B1C2D] w-full lg:w-96 mb-10"
          placeholder="Password"
          value={password}
          onChange={(e) => { setPassword(e.target.value) }}
          type="password"
        /> */}

          <button
            type="submit"
            className="secondary-button w-full mt-6 md:w-64"
            onClick={HandleUpdateProfile}
          >
            Update
          </button>

          <div className="grid grid-cols-1 w-full md:grid-cols-2 justify-start gap-3 my-4">
            {user?.data?.addresses &&
              addressArray?.map((item) => {
                return (
                  <div className="add-address-card flex flex-col gap-2 cursor-pointer p-2">
                    <div className="text-[#FD004E] font-bold">{item.label}</div>
                    <div className="text">
                      {item.line1},{item.line2}
                    </div>
                    <div className="text">
                      {item.city} , {item.state}, {item.country}
                    </div>
                    <div className="text">{item.zipCode}</div>
                  </div>
                );
              })}
            {user?.data?.addresses && (
              <AddAddress
                setAddressArray={setAddressArray}
                isEnabled={enabled}
              />
            )}
          </div>
        </div>
        <div></div>
      </div>
    </section>
  );
};

export default Settings;