import api from "../../../api/axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useLoginMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async ({ emailId, password, admin, setAdmin, setUserId, isRemember }) => {
      const loginResponse = await api.post("/users/login", {
        emailId,
        password,
      });
      const { token } = loginResponse.data;
      localStorage.setItem("token", token)
      localStorage.setItem('isAdmin', loginResponse?.data.user.isAdmin);
      if (!token) {
        throw new Error("Login failed");
      }
      // console.log(loginResponse.data,"bonkers <<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<>")
      setAdmin(loginResponse?.data.user.isAdmin)
      // setAdmin(admin)
      setUserId(loginResponse?.data.user._id)
      return loginResponse.data;
    },
    {
      onSuccess: (loginResponse) => {
        // localStorage.setItem('isAdmin', loginResponse?.data.user.isAdmin);
        navigate("/dashboard/orders");
        toast.success("Login Successful", { toastId: "toast-message" })
        // localStorage.setItem("access_token", loginResponse.accessToken);
        // // localStorage.setItem("refresh_token", refresh_token);
        // return queryClient.invalidateQueries("user");
      },
      onError: (error) => {
        if (error.response?.status === 500 && error.response?.config?.url === "/users/google-login") {
          toast.error("Server error occurred. Please try again later.", { toastId: "toast-message" })
        } else {
          toast.error(error.response.data.message, { toastId: "toast-message" })
        }
      },
    }
  );
};

export default useLoginMutation;
