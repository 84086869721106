import React, { useState, useEffect } from "react";
import useSendOTPMutation from "../../../hooks/mutations/auth/useForgetSendOTP";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import useVerifyOTPMutation from "../../../hooks/mutations/auth/useVerifyOTP";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../../context/Spinner";
import { BiHide } from "react-icons/bi";
import { BiShowAlt } from "react-icons/bi";


var validator = require("validator");

const EmailInput = ({ email, setEmail }) => {
  const [visible, setVisibilty] = useState(false);
  const [visibleconfirm, setVisibiltyConfirm] = useState(false);
  const sendOTPMutation = useSendOTPMutation();
  const verifyOTPMutation = useVerifyOTPMutation();
  const [otpSent, setOtpSent] = useState(false);
  const [OTP, setOTP] = useState();
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const navigate = useNavigate();
  const spinner = useSpinner();



  const handleSendOTP = () => {
    if (!email || !validator.isEmail(email)) {
      toast.error("Please enter a valid email address", {
        toastId: "toast-message",
      });
    } else {
      sendOTPMutation.mutate({
        email: email,
        setOtpSent: setOtpSent,
      });

    }
  };

  const handleSubmitOTP = async () => {
    let response;
    if (!OTP) {
      toast.error("Please enter the OTP", { toastId: "toast-message" });
    } else if (!password || !confirmpassword) {
      toast.error("Please enter the password", { toastId: "toast-message" });
    } else if (password != confirmpassword) {
      toast.error("Passwords dont match", { toastId: "toast-message" });
    } else {
      spinner.setLoadingState(true);
      verifyOTPMutation.mutate({
        emailId: email,
        otp: OTP,
        password: password,
      });
      spinner.setLoadingState(false);
    }
  };
  const togglePassword = () => {
    setVisibilty(!visible);
    console.log("test");
  };
  const toggleConfirmPassword = () => {
    setVisibiltyConfirm(!visibleconfirm);
    console.log("test");
  };
  useEffect(() => {
    console.log(visible);
  }, [visible]);
  useEffect(() => { }, [visibleconfirm]);
  return (
    <div className="flex flex-col gap-3">
      <p className="text-4xs">Please enter your registered email id.</p>
      <span class="text-xl">Email Id</span>
      <input
        label="Email"
        className="input input-bordered bg-[#1B1C2D] w-full"
        autoComplete="off"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      {otpSent && (
        <div className="flex flex-col gap-3">
          <span class="text-xl">OTP</span>
          <OTPInput
            value={OTP}
            inputStyles={{
              background: "#1B1C2D",
              border: "none",
              outline: "none",
              borderRadius: "8px",
              fontSize: "20px",
              height: "42px",
              width: "42px",
            }}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
          <div>
            <div>
              <label class="text-xl">New Password</label>
              <div class="relative my-3">
                <input
                  label="Password"
                  type={visible ? "text" : "password"}
                  className="input input-bordered bg-[#1B1C2D] w-full "
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <span
                  onClick={togglePassword}
                  class="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                >
                  {visible ? (
                    <BiHide className="w-8 h-8" />
                  ) : (
                    <BiShowAlt className="w-8 h-8" />
                  )}
                </span>
              </div>
              <label class="text-xl ">Confirm New Password</label>
              <div class="relative my-3">
                <input
                  label="Password"
                  type={visibleconfirm ? "text" : "password"}
                  className="input input-bordered bg-[#1B1C2D] w-full "
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <span
                  onClick={toggleConfirmPassword}
                  class="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                >
                  {visibleconfirm ? (
                    <BiHide className="w-8 h-8" />
                  ) : (
                    <BiShowAlt className="w-8 h-8" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {otpSent ? (
        <button
          type="submit"
          onClick={handleSubmitOTP}
          // disabled={loginMutation.isLoading}
          className="secondary-button disabled:bg-opacity-70 w-full mt-6"
        >
          Update Password
        </button>
      ) : (
        <button
          type="submit"
          onClick={handleSendOTP}
          // disabled={loginMutation.isLoading}
          className="secondary-button disabled:bg-opacity-70 w-full mt-6"
        >
          Send OTP
        </button>
      )}
    </div>
  );
};

export default EmailInput;
