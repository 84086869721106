import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api/axios";
import { toast } from "react-toastify";

const ThankYou = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      api
        .post("https://prod-api.3dspot.io/api/v1/orders/payment", {
          orderId: orderId,
          paymentId: "",
        })
        .then((data) => {
          console.log(data);
          toast.success("Payment Successful");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Failed to process payment");
        });
    }
  }, [orderId]);

  const handleDashboardClick = () => {
    navigate("/dashboard/orders");
  };

  return (
    <div className="flex flex-col items-center justify-center pt-12">
      <h1 className="text-4xl font-bold mb-4 text-white">Thank You</h1>
      <p className="text-lg text-white">
        Your payment has been successfully processed.
      </p>
      <button className="secondary-button mt-4" onClick={handleDashboardClick}>
        Dashboard
      </button>
    </div>
  );
};

export default ThankYou;
