// api/axios.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { queryClient } from '../index'; 

const LIVE_URL = 'https://prod-api.3dspot.io/api/v1';
const TEST_URL = 'http://localhost:5001/api/v1';

const api = axios.create({
  baseURL: LIVE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      localStorage.removeItem('isAdmin');
      window.location.href = '/login'; // Redirect to login
      return Promise.reject(new Error("Token expired"));
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    queryClient.setQueryData("user", undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    window.location.href = '/login'; // Redirect to login
  }
  return Promise.reject(error);
});

export default api;
