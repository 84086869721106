import React from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import useGetUser from "../../../hooks/queries/useGetUser";
import ReactModal from "react-modal";
import { toast } from "react-toastify";

import api from "../../../api/axios";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh",
    overflowY: "auto",
    padding: "20px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    background: "#fff",
  },
};

const buttonStyle = {
  backgroundColor: "#4CAF50",
  color: "#fff",
  padding: "10px 15px",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "16px",
  marginTop: "20px",
};

const CheckoutModal = ({ subscriptionData, showModal, handleCloseModal }) => {
  const user = useGetUser();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubscription = async () => {
    if (!user || !elements) {
      return;
    }

    try {
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name: user.data.firstName + " " + user.data.lastName,
          email: user.data.emailId,
        },
      });

      const response = await api.post("orders/create-subscription", {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        plan: subscriptionData.id,
        name: user.data.firstName + " " + user.data.lastName,
        email: user.data.emailId,
        priceId: subscriptionData.yearlyBilling
          ? subscriptionData.yearlyPriceId
          : subscriptionData.monthlyPriceId,
      });

      const confirmPayment = await stripe?.confirmCardPayment(
        response.data.clientSecret
      );
      if (confirmPayment?.error) {
        // Handle specific error cases
        if (confirmPayment.error.payment_intent) {
          // Payment requires additional action (e.g., 3D Secure)
          // Handle the action and try confirming again
          // You might redirect the user to a 3D Secure page or show a modal
          console.log("Payment requires additional action:", confirmPayment.error.payment_intent);
        } else {
          // Other payment errors
          console.error(confirmPayment.error.message);
          toast.error("Subscription failed: " + confirmPayment.error.message);
        }
      } else {
        toast.success("Subscription successful");
        handleCloseModal();
      }
    } catch (error) {
      console.error(error);
      toast.error("Subscription failed");
    }
  };

  return (
    <ReactModal
  isOpen={showModal}
  onRequestClose={handleCloseModal}
  style={modalStyle}
  ariaHideApp={false}
>
  <h2 style={{ marginBottom: "20px", color: "#333",display: "flex", justifyContent: "center"}}>
   <b>Unlock your subscription Now</b>
  </h2>
  <CardElement style={{ marginBottom: "20px", border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }} />
  <div style={{ display: "flex", justifyContent: "center" }}>
    <button className="btn secondary-button mt-4" onClick={handleSubscription}>
      Subscribe Now
    </button>
  </div>
</ReactModal>

  );
};

export default CheckoutModal;