import React from 'react'
import "../../../sass/files/card.scss"

const Discount = () => {
    return (
        <div className="flex flex-col discount-card w-full p-4">
            <p className="text-xl text-[#7E7E7E] uppercase my-2">Limited Period Deal</p>
            <p className="text-4xl font-bold mb-6">Become our Patreon today <br />
                <strong className="text-accent">Get 50%</strong> flat discount🔥</p>
            <button className="secondary-button mt-3 w-48">Subscribe</button>
        </div>
    )
}

export default Discount
